import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import { Container, Row, Col } from "reactstrap"

//Import Images
import error from "../../assets/images/error-img.png"

const TrialExpired = () => {
  document.title = "402 Trial expired"

  useEffect(() => {
    localStorage.removeItem("authUser")
  }, [history])

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-5">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <h1 className="display-2 fw-medium">
                  4<i className="bx bx-buoy bx-spin text-primary display-3" />2
                </h1>
                <h4 className="text-uppercase">Trial expired</h4>
                <p>
                  We're sorry to inform you that your trial period has
                  concluded. <br />
                  For further assistance or to explore your options, please
                  don't hesitate to reach out to our support team at:
                  <br />
                  <br />
                  <a href="mailto:hello@chipsconnect.com">
                    hello@chipsconnect.com
                  </a>
                </p>
                <div className="mt-2 text-center">
                  <Link
                    className="btn btn-primary waves-effect waves-light "
                    to="/logout"
                  >
                    Back to Home
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="8" xl="6">
              <div>
                <img src={error} alt="" className="img-fluid" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default TrialExpired
