import React, { useEffect, useState } from "react"
import "./partsearch.css"
import { Alert, Col, Container, Row } from "reactstrap"
import "flatpickr/dist/themes/material_blue.css"
import { withTranslation } from "react-i18next"
import SearchResults from "./SearchResults"
import { useLoading } from "helpers/custom_hooks"
import { GetParts } from "helpers/backend_helper"
import { displayMessage } from "helpers/misc_helpers"

const Dashboard = props => {
  const setLoading = useLoading()
  const [parts, setParts] = useState([])
  const [error, setError] = useState()
  const [showResults, setShowResults] = useState(false)
  const queryParameters = new URLSearchParams(window.location.search)
  const search = queryParameters.get("search")

  const searchParts = async _ => {
    setLoading(true)
    try {
      const result = await GetParts(search)
      setParts(result)
      setShowResults(true)
    } catch (error) {
      if (error?.response?.status == 429)
        displayMessage(
          "You exceeded your daily limit of searches. Please upgrade to premium license!",
          setError
        )
      else displayMessage("An unexpected error happened!", setError)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (!search) setShowResults(false)
    else searchParts()
  }, [search])

  return (
    <React.Fragment>
      <section
        className="section hero-section bg-ico-hero"
        id="home"
        style={{
          paddingTop: showResults ? "120px" : "150px",
          paddingBottom: showResults ? "50px" : "100px",
        }}
      >
        <div className="bg-overlay bg-primary"></div>
        <Container>
          <Row className="align-items-center">
            <Col lg={5}>
              <div className="text-white-50">
                <h3 className="text-white fw-semibold mb-3">Search Part</h3>
                <form className="app-search d-none d-lg-block">
                  <div className="position-relative">
                    <input
                      type="text"
                      name="search"
                      className="form-control"
                      placeholder="Search „Manufacturer Part Number“..."
                      defaultValue={search ?? ""}
                    />
                    <span className="bx bx-search-alt" />
                  </div>
                </form>

                <div className="d-flex flex-wrap gap-2 mt-4"></div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {error && (
        <Alert className="mt-4" color="danger">
          {error}
        </Alert>
      )}

      {showResults && <SearchResults parts={parts} search={search} />}
    </React.Fragment>
  )
}

export default withTranslation()(Dashboard)
