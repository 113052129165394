import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  Table,
  Button,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import { DeleteUser, GetUsers } from "helpers/backend_helper"
import { useLoading, useShowLicenseModal } from "helpers/custom_hooks"
import UserModal from "./UserModal"
import { GetLoggedOnUser, IsPremiumUser } from "helpers/misc_helpers"

const Users = _ => {
  const [selectedUser, setSelectedUser] = useState()
  const [users, setUsers] = useState([])
  const [userModal, setUserModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const setLoading = useLoading()
  const loggedOnUser = GetLoggedOnUser()
  const showLicenseModal = useShowLicenseModal()

  const toggle = () => {
    setUserModal(!userModal)
  }

  const handleUserClick = arg => {
    setSelectedUser(arg)
    if (arg) setIsEdit(true)

    toggle()
  }

  const onClickDelete = users => {
    setSelectedUser(users)
    setDeleteModal(true)
  }

  const handleDeleteUser = async () => {
    if (selectedUser && selectedUser.id) await DeleteUser(selectedUser.id)
    loadData()
    setDeleteModal(false)
  }

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    setLoading(true)
    const result = await GetUsers()
    setUsers(result)
    setSelectedUser()
    setIsEdit(false)
    setLoading(false)
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Users" breadcrumbItem="User List" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col>
                      <div className="text-sm-end">
                        <Button
                          type="button"
                          color="primary"
                          className="btn mb-2 me-2"
                          onClick={_ => {
                            IsPremiumUser()
                              ? handleUserClick()
                              : showLicenseModal(true)
                          }}
                        >
                          <i className="mdi mdi-plus-circle-outline me-1" />
                          Create New User
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Firstname</th>
                        <th>Lastname</th>
                        <th>Email</th>
                        <th>Type</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.length === 0 && (
                        <tr>
                          <td colSpan={5} className="text-center">
                            There are currently no active users.
                          </td>
                        </tr>
                      )}
                      {users.map((user, index) => (
                        <tr key={index}>
                          <td>
                            <div className="avatar-xs">
                              <span className="avatar-title rounded-circle">
                                {user.firstname.charAt(0)}
                              </span>
                            </div>
                          </td>
                          <td className="align-middle">{user.firstname}</td>
                          <td className="align-middle">{user.lastname}</td>
                          <td className="align-middle">{user.email}</td>
                          <td className="align-middle">{user.userType}</td>
                          <td className="align-middle">
                            <div className="d-flex gap-3">
                              <Link
                                to="#"
                                className="text-success"
                                onClick={() => {
                                  handleUserClick(user)
                                }}
                              >
                                <i
                                  className="mdi mdi-pencil font-size-18"
                                  id="edittooltip"
                                />
                                <UncontrolledTooltip
                                  placement="top"
                                  target="edittooltip"
                                >
                                  Edit
                                </UncontrolledTooltip>
                              </Link>
                              {loggedOnUser._id != user.id && (
                                <Link
                                  to="#"
                                  className="text-danger"
                                  onClick={() => {
                                    onClickDelete(user)
                                  }}
                                >
                                  <i
                                    className="mdi mdi-delete font-size-18"
                                    id="deletetooltip"
                                  />
                                  <UncontrolledTooltip
                                    placement="top"
                                    target="deletetooltip"
                                  >
                                    Delete
                                  </UncontrolledTooltip>
                                </Link>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <UserModal
        isOpen={userModal}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        selectedUser={selectedUser}
        toggle={toggle}
        loadData={loadData}
      />
    </React.Fragment>
  )
}

export default withRouter(Users)
