import React from "react"
import PropTypes from "prop-types"
import withRouter from "./Common/withRouter"
import logo from "../assets/images/logo-dark.png"

const NonAuthLayout = props => {
  return (
    <React.Fragment>
      {props.children}
      <img
        src={logo}
        style={{
          position: "absolute",
          top: "2%",
          left: "50%",
          width: "10%",
          minWidth: "150px",
          translate: "-50%",
        }}
      />
    </React.Fragment>
  )
}

NonAuthLayout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object,
}

export default withRouter(NonAuthLayout)
