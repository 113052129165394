import PropTypes from "prop-types"
import React from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col } from "reactstrap"

const CardPricing = props => {
  return (
    <React.Fragment>
      <Col xl="4" md="6">
        <Card
          className="plan-box"
          style={{
            boxShadow: "0 0.75rem 1.5rem rgba(18, 38, 63, 0.1) !imporant",
          }}
        >
          <CardBody className="p-4">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h5>{props.pricing.title}</h5>
                <p className="text-muted">{props.pricing.description}</p>
              </div>

              <div className="flex-shrink-0 ms-3">
                <i
                  className={"bx " + props.pricing.icon + " h1 text-primary"}
                  style={{ lineHeight: 1 }}
                />
              </div>
            </div>
            {props.pricing.price && (
              <div className="py-4">
                <h2>
                  <sup>€</sup> {props.pricing.price}/{" "}
                  <span className="font-size-13">{props.pricing.duration}</span>
                </h2>
              </div>
            )}
            <div
              className="text-center plan-btn"
              style={{ opacity: props.pricing.link ? "1" : "0" }}
            >
              <button
                className={`btn btn-${
                  props.pricing.disabled ? "light" : "primary"
                } btn-sm waves-effect waves-light`}
                disabled={props.pricing.disabled}
                onClick={_ => {
                  props.pricing.link()
                }}
              >
                {props.pricing.btnText}
              </button>
            </div>
            <p className="text-muted mt-4">{props.pricing.description2}</p>

            {props.pricing.features?.length > 0 && (
              <div className="plan-features mt-5">
                {props.pricing.features.map((feature, key) => (
                  <p key={"_feature_" + key} className="d-flex">
                    <i
                      className={`${feature.icon} text-${feature.color} font-size-18 me-2`}
                    />{" "}
                    {feature.title}
                  </p>
                ))}
              </div>
            )}
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

CardPricing.propTypes = {
  pricing: PropTypes.object,
}

export default CardPricing
