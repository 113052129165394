import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Label,
  UncontrolledTooltip,
  Row,
  Button,
} from "reactstrap"

//SimpleBar
import SimpleBar from "simplebar-react"
// import images

const QCComments = props => {
  const [newMessage, setNewMessage] = useState("")

  const sendMessage = () => {}

  return (
    <React.Fragment>
      <Col>
        <Card>
          <CardBody>
            <SimpleBar style={{ maxHeight: "300px" }}>
              <div>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item py-3">
                    <div className="d-flex">
                      <div className="avatar-xs me-3">
                        <div className="avatar-title rounded-circle bg-light text-primary">
                          <i className="bx bxs-user"></i>
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="font-size-14 mb-1">
                          Delores Williams{" "}
                          <small className="text-muted float-end">
                            1 hr Ago
                          </small>
                        </h5>
                        <p className="text-muted">
                          If several languages coalesce, the grammar of the
                          resulting of the individual
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <Row className="mt-3 mx-0">
                <Col>
                  <div className="position-relative">
                    <input
                      type="text"
                      value={newMessage.message ?? ""}
                      onChange={e =>
                        setNewMessage({
                          ...newMessage,
                          message: e.target.value,
                        })
                      }
                      className="form-control chat-input"
                      placeholder="Enter Comment..."
                    />
                  </div>
                </Col>
                <Col className="col-auto d-flex align-items-end">
                  <Button
                    type="button"
                    color="primary"
                    onClick={sendMessage}
                    className="btn btn-rounded chat-send w-md "
                  >
                    <span className="d-none d-sm-inline-block me-2">Send</span>{" "}
                    <i className="mdi mdi-send" />
                  </Button>
                </Col>
              </Row>{" "}
            </SimpleBar>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default QCComments
