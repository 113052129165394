import React from "react"
import { Link } from "react-router-dom"
import { Container, Row, Col } from "reactstrap"

//Import Images
import error from "../../assets/images/error-img.png"

const Pages403 = () => {
  //meta title
  document.title = "403 Error Page"

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-5">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <h1 className="display-2 fw-medium">
                  4<i className="bx bx-buoy bx-spin text-primary display-3" />3
                </h1>
                <h4 className="text-uppercase">
                  Sorry, you are not allowed to view this page
                </h4>
                <p className="text-primary">
                  This is a premium feature. To use this feature, please upgrade
                  your license!
                </p>
                <div className="mt-5 text-center">
                  <Link
                    className="btn btn-primary waves-effect waves-light "
                    to="/settings?tab=2"
                  >
                    Upgrade Account
                  </Link>
                  <br />
                  <Link className=" d-block mt-4" to="/dashboard">
                    <i className="bx bx-left-arrow-alt" />
                    Back to Dashboard
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="8" xl="6">
              <div>
                <img src={error} alt="" className="img-fluid" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Pages403
