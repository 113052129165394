import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

import { Link, useSearchParams } from "react-router-dom"

// Formik validation
import * as Yup from "yup"
import { Formik, Form } from "formik"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo-sm.png"
import { LoginUser } from "helpers/backend_helper"
import withRouter from "components/Common/withRouter"
import { useLoading } from "helpers/custom_hooks"
import { displayMessage } from "helpers/misc_helpers"
import FormikInputField from "helpers/FormikInputField"
import FormikPasswordField from "helpers/FormikPasswordField"

const Login = props => {
  const [error, setError] = useState()
  const setLoading = useLoading()
  let [search, _] = useSearchParams()
  let searchParams = new URLSearchParams(search)
  let authUser = searchParams.get("authUser")
  let redirectURL = searchParams.get("redirectURL")

  const initialValues = {
    email: "",
    password: "",
  }

  const validationSchema = Yup.object({
    email: Yup.string().required("Please Enter Your Email"),
    password: Yup.string().required("Please Enter Your Password"),
  })

  const handleSubmit = async values => {
    try {
      setLoading(true)
      const result = await LoginUser(values)
      localStorage.setItem("authUser", result)
      if (redirectURL) props.router.navigate(redirectURL)
      else props.router.navigate("/dashboard")
    } catch (err) {
      setLoading(false)

      err?.response?.status == 400
        ? displayMessage(err.response.data, setError)
        : displayMessage("An unhandled exception happend!", setError)
    }
  }

  useEffect(() => {
    if (authUser) {
      localStorage.setItem("authUser", authUser)
      window.location.href = "/dashboard"
    }
  }, [])

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to chipsconnect.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                    >
                      {({ errors, touched }) => (
                        <Form>
                          {error ? <Alert color="danger">{error}</Alert> : null}

                          <FormikInputField
                            fieldName="email"
                            type="email"
                            label="Email"
                            errors={errors}
                            touched={touched}
                          />
                          <FormikPasswordField
                            fieldName="password"
                            fieldType="password"
                            label="Password"
                            errors={errors}
                            touched={touched}
                          />

                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-primary btn-block"
                              type="submit"
                            >
                              Log In
                            </button>
                          </div>

                          <div className="mt-4 text-center">
                            <Link to="/forgot-password" className="text-muted">
                              <i className="mdi mdi-lock me-1" />
                              Forgot your password?
                            </Link>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Don&#39;t have an account ?{" "}
                  <Link to="/register" className="fw-medium text-primary">
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p>
                <p>© {new Date().getFullYear()} chipsconnect</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
