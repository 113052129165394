import React from "react"
import { Navigate } from "react-router-dom"
// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import RegisterWizard from "pages/Authentication/RegistrationWizard"
import PartsUpload from "pages/PartsUpload"
import PartSearch from "pages/PartSearch"
import PartSearchDetails from "pages/PartSearchDetails"
import Settings from "pages/Settings"
import Pages404 from "pages/Util/pages-404"
import Users from "pages/Users"
import ConfirmMail from "pages/Authentication/ConfirmEmail"
import ConfirmMailChange from "pages/Authentication/ConfirmEmailChange"
import ResetPassword from "pages/Authentication/ChangePassword"
import Requests from "pages/Requests"
import SavedParts from "pages/SavedParts"
import Watchlist from "pages/Watchlist"
import WatchlistUpload from "pages/WatchlistUpload"
import Pages403 from "pages/Util/pages-403"
import AccountPendingConfirmation from "pages/Util/AccountPendingConfirmation"
import TrialExpired from "pages/Util/TrialExpired"
import { IsPremiumUser } from "helpers/misc_helpers"
import QCBuyer from "pages/QCBuyer"
import QCWizard from "pages/QCRequest"
import QCDetails from "pages/QCDetails"

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/parts-upload", component: <PartsUpload /> },
  { path: "/part-search", component: <PartSearch /> },
  { path: "/part-search/:partID", component: <PartSearchDetails /> },
  {
    path: "/users",
    component: <Users />,
    checkUser: user => user.userType != "Admin",
  },
  { path: "/settings", component: <Settings /> },
  {
    path: "/requests",
    component: <Requests />,
    checkUser: user => !IsPremiumUser(),
  },
  { path: "/saved-parts", component: <SavedParts /> },
  { path: "/watchlist", component: <Watchlist /> },
  { path: "/qc-list", component: <QCBuyer /> },
  { path: "/qc-wizard", component: <QCWizard /> },
  { path: "/qc-details", component: <QCDetails /> },
  { path: "/watchlist-upload", component: <WatchlistUpload /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
]

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
  { path: "/email-confirmation", component: <ConfirmMail /> },
  { path: "/change-email-confirmation", component: <ConfirmMailChange /> },
  { path: "/register-wizard", component: <RegisterWizard /> },
  { path: "/reset-password", component: <ResetPassword /> },
  { path: "/403", component: <Pages403 /> },
  { path: "/trial-expired", component: <TrialExpired /> },
  {
    path: "/account-confirmation-pending",
    component: <AccountPendingConfirmation />,
  },
  { path: "*", component: <Pages404 /> },
]

export { authProtectedRoutes, publicRoutes }
