import { GetLoggedOnUser } from "helpers/misc_helpers"
import React from "react"
import { Navigate } from "react-router-dom"

const Authmiddleware = props => {
  const loggedOnUser = GetLoggedOnUser()

  if (!localStorage.getItem("authUser")) {
    const searchParams = new URLSearchParams({
      redirectURL: window.location.pathname + window.location.search,
    }).toString()

    return (
      <Navigate
        to={{
          pathname: "/login",
          search: `?${searchParams}`,
          state: { from: props.location },
        }}
      />
    )
  }

  if (props.checkUser)
    if (props.checkUser(loggedOnUser))
      return (
        <Navigate to={{ pathname: "/403", state: { from: props.location } }} />
      )

  return <React.Fragment>{props.children}</React.Fragment>
}

export default Authmiddleware
