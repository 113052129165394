import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Col, Form, Row, TabPane, Tooltip } from "reactstrap"

const AccountTypeTab = ({ nextTab, previousTab }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const toggle = () => setTooltipOpen(!tooltipOpen)

  return (
    <TabPane tabId={1}>
      <Form>
        <Row>
          <Col lg="12">
            <h5 className="font-size-14 mb-4">Business Account</h5>
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="accountOption"
                id="option1"
                value="option2"
                defaultChecked
              />
              <label
                id="accountTypeInfo"
                className="form-check-label"
                htmlFor="option1"
              >
                OEM/CEM/EMS <i className="bx bx-info-circle font-size-16"></i>
              </label>
              <Tooltip
                id="tooltip-register"
                placement="top"
                isOpen={tooltipOpen}
                autohide={false}
                target="accountTypeInfo"
                toggle={toggle}
              >
                OEM - Original Equipment Manufacturer <br />
                CEM - Contract Electronics Manufacturer <br />
                EMS - Electronics Manufacturing Services
              </Tooltip>
            </div>

            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="accountOption"
                id="option4"
                value="option2"
                disabled
              />
              <label className="form-check-label" htmlFor="option4">
                Distributor - coming soon <br />
                (Only for stock checking and market information,
                <br /> no access to stock information from OEM/CEM/EMS)
              </label>
            </div>

            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="accountOption"
                id="option5"
                value="option2"
                disabled
              />
              <label className="form-check-label" htmlFor="option5">
                Component manufacturer - coming soon <br />
                (Only for stock checking and market information,
                <br /> no access to stock information from OEM/CEM/EMS)
              </label>
            </div>
          </Col>
        </Row>
        <div className="d-flex justify-content-end">
          <Link
            to="#"
            className="btn btn-primary disabled me-3"
            onClick={previousTab}
          >
            Previous
          </Link>
          <Link to="#" className="btn btn-primary" onClick={nextTab}>
            Next
          </Link>
        </div>
      </Form>
    </TabPane>
  )
}

export default AccountTypeTab
