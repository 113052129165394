import React, { useState } from "react"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
  Label,
  Input,
} from "reactstrap"
import * as Yup from "yup"
import { Formik, Form, Field } from "formik"
import { CreateUser, UpdateUser } from "helpers/backend_helper"
import { useLoading } from "helpers/custom_hooks"
import FormikInputField from "helpers/FormikInputField"
import { GetLoggedOnUser, displayMessage } from "helpers/misc_helpers"

const UserModal = ({
  isOpen,
  toggle,
  selectedUser,
  isEdit,
  setIsEdit,
  loadData,
}) => {
  const setLoading = useLoading()
  const [error, setError] = useState()
  const loggedOnUser = GetLoggedOnUser()

  const initialValues = {
    firstname: selectedUser?.firstname ?? "",
    lastname: selectedUser?.lastname ?? "",
    email: selectedUser?.email ?? "",
    userType: selectedUser?.userType ?? "",
  }

  const validationSchema = Yup.object({
    firstname: Yup.string().required("Please Enter Your Name"),
    lastname: Yup.string().required("Please Enter Your Designation"),
    email: Yup.string()
      .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Please Enter Valid Email")
      .required("Please Enter Your Email"),
  })

  const handleSubmit = async values => {
    setLoading(true)

    try {
      if (isEdit) {
        await UpdateUser({
          id: selectedUser.id,
          companyID: selectedUser.companyID,
          ...values,
        })
        setIsEdit(false)
      } else {
        await CreateUser(values)
      }
      toggle()
      loadData()
    } catch (error) {
      setLoading(false)
      if (error?.response?.status === 400)
        return displayMessage(error.response.data, setError)
      displayMessage("An unexpected error happened!", setError)
      console.error(error)
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4">
        {!!isEdit ? "Edit User" : "Add User"}
      </ModalHeader>
      <ModalBody>
        {error ? <Alert color="danger">{error}</Alert> : null}

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <FormikInputField
                fieldName="firstname"
                label="Firstname"
                errors={errors}
                touched={touched}
              />
              <FormikInputField
                fieldName="lastname"
                label="Lastname"
                errors={errors}
                touched={touched}
              />
              <FormikInputField
                fieldName="email"
                label="Email"
                errors={errors}
                touched={touched}
                disabled={isEdit}
              />
              <div className="mb-3">
                <Label for="basicpill-cstno-input7">Type:</Label>
                <Field
                  as="select"
                  className="form-select"
                  name="userType"
                  disabled={loggedOnUser._id == selectedUser?.id}
                >
                  <option disabled value="">
                    -
                  </option>
                  <option value="Standard">Standard</option>
                  <option value="Admin">Admin</option>
                </Field>
                {errors.userType && touched.userType && (
                  <div className="text-danger">{errors.userType}</div>
                )}
              </div>
              <div className="form-check mt-3">
                <Input
                  type="checkbox"
                  className="form-check-input"
                  id="legalnotice"
                  required
                />
                <label htmlFor="legalnotice" className="form-check-label">
                  The user accepts the{" "}
                  <a
                    href="https://chipsconnect.com/terms"
                    className="text-primary"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    Terms of Use.
                  </a>{" "}
                </label>
              </div>
              <div className="form-check mt-3">
                <Input
                  type="checkbox"
                  className="form-check-input"
                  id="legalnotice"
                  required
                />
                <label htmlFor="legalnotice" className="form-check-label">
                  The user accepts the{" "}
                  <a
                    href="https://chipsconnect.com/privacy_policy"
                    className="text-primary"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    Privacy Policy.
                  </a>{" "}
                </label>
              </div>
              <div className="form-check mt-3">
                <Input
                  type="checkbox"
                  className="form-check-input"
                  id="legalnotice"
                  required
                />
                <label htmlFor="legalnotice" className="form-check-label">
                  I hereby confirm that the user is authorized to use the
                  chipsconnect platform on behalf of your company and to
                  represent your company in conducting all necessary functions
                  and transactions.
                </label>
              </div>
              <Row>
                <Col>
                  <div className="text-end">
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  )
}

export default UserModal
