import React, { useState } from "react"
import { Tooltip } from "reactstrap"

function PremiumBadge({ accountType }) {
  const [tooltipOpen, setTooltipOpen] = useState(false)

  return (
    <div>
      {accountType == "Premium" && (
        <>
          <i
            className="text-primary bx bx-badge-check font-size-18 ms-1"
            id="PremiumFeatureIcon"
            onMouseOver={() => setTooltipOpen(true)}
            onMouseOut={() => setTooltipOpen(false)}
          />
          <Tooltip
            placement="right"
            isOpen={tooltipOpen}
            target="PremiumFeatureIcon"
          >
            Premium Account
          </Tooltip>
        </>
      )}
    </div>
  )
}

export default PremiumBadge
