import React from "react"

import { Col, TabPane } from "reactstrap"

const ConfirmationTab = props => {
  return (
    <TabPane tabId={6}>
      <div className="row justify-content-center">
        <Col lg="6">
          <div className="text-center">
            <div className="mb-4">
              <i className="mdi mdi-check-circle-outline text-primary display-4" />
            </div>
            <div>
              <h5>Thanks for registration!</h5>
              <p className="text-muted">
                Your account is under review. You will receive an email after
                the review process.
              </p>
              <a href="https://chipsconnect.com" className="btn btn-primary">
                Back home
              </a>
            </div>
          </div>
        </Col>
      </div>
    </TabPane>
  )
}

export default ConfirmationTab
