import React, { useEffect, useState } from "react"
import { Alert, Col, Container, Row } from "reactstrap"
import "flatpickr/dist/themes/material_blue.css"
import { withTranslation } from "react-i18next"
import PartList from "./PartList"
import { useLoading } from "helpers/custom_hooks"
import { GetParts, GetSavedParts, GetWatchlist } from "helpers/backend_helper"
import Breadcrumbs from "../../components/Common/Breadcrumb"

const Watchlist = props => {
  const setLoading = useLoading()
  const [parts, setParts] = useState([])

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async _ => {
    setLoading(true)
    const result = await GetWatchlist()
    setParts(result)
    setLoading(false)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Parts")}
            breadcrumbItem={props.t("Watchlist")}
          />

          <PartList parts={parts} loadParts={loadData} />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Watchlist)
