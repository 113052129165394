import { RequestDowngrade, RequestUpgrade } from "helpers/backend_helper"
import { useLoading } from "helpers/custom_hooks"
import React, { useState } from "react"
import { Alert, Modal, ModalBody } from "reactstrap"

const AccountDowngradeModal = ({ show, onCancel, onSuccess }) => {
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState()
  const setLoading = useLoading()

  const handleSubmit = async () => {
    try {
      setLoading(true)
      await RequestDowngrade()
      setSuccess(true)
    } catch (error) {
      console.log(error)
      setError("An unexpected error happened! Please try again later")
    }
    setLoading(false)
  }

  return (
    <Modal size="m" isOpen={show} toggle={onCancel} centered={true}>
      <div className="modal-content">
        {!success ? (
          <ModalBody className="px-4 py-5 pb-3 text-center">
            <button
              type="button"
              onClick={onCancel}
              className="btn-close position-absolute end-0 top-0 m-3"
            ></button>
            {error ? <Alert color="danger">{error}</Alert> : null}

            <h4>Account Downgrade</h4>
            <p className="text-muted mb-4">
              Are you sure you want to downgrade your account?
            </p>

            <div className="hstack gap-2 justify-content-center mb-0">
              <button
                type="button"
                className="btn btn-secondary w-100"
                onClick={onCancel}
              >
                No
              </button>
              <button
                type="button"
                className="btn btn-primary w-100"
                onClick={handleSubmit}
              >
                Yes
              </button>
            </div>
          </ModalBody>
        ) : (
          <ModalBody className="px-4 py-5 pb-3 text-center">
            <button
              type="button"
              onClick={onCancel}
              className="btn-close position-absolute end-0 top-0 m-3"
            ></button>
            <h4>Thank you!</h4>

            <p className="text-muted mb-4">
              Thank you for your request. Our support team is working on it and
              will address it as quickly as possible.
            </p>

            <div className="hstack gap-2 justify-content-center mb-0">
              <button
                type="button"
                className="btn btn-primary w-100"
                onClick={_ => {
                  onSuccess()
                }}
              >
                Close
              </button>
            </div>
          </ModalBody>
        )}
      </div>
    </Modal>
  )
}

export default AccountDowngradeModal
