import { Field } from "formik"
import React, { useState } from "react"
import { Button, Input, Label } from "reactstrap"

const FormikPasswordField = ({
  fieldName,
  label,
  touched,
  errors,
  disabled = false,
  className,
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false)

  return (
    <div className={"mb-3 " + className}>
      <Label className="form-label">{label}</Label>

      <div className="input-group rounded bg-light">
        <Field
          name={fieldName}
          type={passwordVisible ? "text" : "password"}
          as={CustomInput}
          invalid={errors[fieldName] && touched[fieldName]}
          disabled={disabled}
        />
        <Button
          color="primary"
          type="button"
          id="button-addon2"
          style={{ borderRadius: "0 0.25rem 0.25rem 0" }}
          onClick={_ => setPasswordVisible(!passwordVisible)}
        >
          <i className="mdi mdi-eye"></i>
        </Button>
      </div>
    </div>
  )
}

const CustomInput = props => <Input className="form-control" {...props} />

export default FormikPasswordField
