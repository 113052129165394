import React from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "flatpickr/dist/themes/material_blue.css"
import { Link } from "react-router-dom"
import { formatDate } from "helpers/misc_helpers"
const { SearchBar } = Search

const PartList = ({ parts }) => {
  const node = React.createRef()
  const columns = [
    {
      dataField: "partNumber",
      text: "Part no.",
      sort: true,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
    },
    {
      dataField: "manufacturer",
      text: "Manufacturer",
      sort: true,
    },
    {
      dataField: "quantity",
      text: "Stock",
      sort: true,
      formatter: cell => <>{cell} pc.</>,
      headerStyle: _ => ({
        textAlign: "right",
      }),
      align: () => "right",
    },
    {
      dataField: "price",
      text: "Unit Price",
      sort: true,
      formatter: (cell, row) => (
        <>{cell ? cell.toFixed(5) + " " + row.currency : "-"}</>
      ),
      headerStyle: _ => ({
        textAlign: "right",
      }),
      align: () => "right",
    },
    {
      dataField: "type",
      text: "Type",
      sort: true,
      headerStyle: _ => ({
        textAlign: "center",
      }),
      align: () => "center",
    },
    {
      dataField: "region",
      text: "Region",
      sort: true,
    },
    {
      dataField: "dateCreated",
      text: "Last Update",
      sort: true,
      formatter: cell => <>{formatDate(cell)}</>,
      headerStyle: _ => ({
        textAlign: "center",
      }),
      align: () => "center",
    },
    {
      dataField: "id",
      text: "",
      formatter: (cellContent, row) => (
        <>
          <Link to={`/part-search/${row.partID}`} className="btn btn-primary ">
            Request Part
          </Link>
        </>
      ),
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: parts.length,
    custom: true,
  }

  const selectRow = {
    mode: "radio",
    hideSelectColumn: true,
    clickToSelect: true,
  }

  return (
    <Card>
      <CardBody>
        <PaginationProvider
          pagination={paginationFactory(pageOptions || [])}
          keyField="id"
          columns={columns || []}
          data={parts || []}
        >
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="id"
              data={parts}
              columns={columns || []}
              remote={{ filter: true }}
              bootstrap4
              search
            >
              {toolkitProps => (
                <React.Fragment>
                  <Row>
                    <Col>
                      <SearchBar {...toolkitProps.searchProps} />
                    </Col>
                  </Row>
                  <div className="table-responsive">
                    <BootstrapTable
                      {...toolkitProps.baseProps}
                      {...paginationTableProps}
                      bordered={false}
                      hover={true}
                      selectRow={selectRow}
                      classes={"table align-middle table-nowrap table-check"}
                      headerWrapperClasses={"table-light"}
                      ref={node}
                    />
                  </div>
                  <div className="pagination pagination-rounded justify-content-end mb-2">
                    <PaginationListStandalone {...paginationProps} />
                  </div>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      </CardBody>
    </Card>
  )
}

export default PartList
