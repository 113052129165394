import React from "react"
import { Col, Row } from "reactstrap"
import * as Yup from "yup"
import { Form, Formik } from "formik"
import FormikInputField from "helpers/FormikInputField"
import { ChangeEmail, UpdateUser } from "helpers/backend_helper"
import { displayMessage } from "helpers/misc_helpers"

const PersonalSettingsTab = ({ user, setLoading, setError, setSuccess }) => {
  const initialValues = {
    id: user?.id,
    firstname: user?.firstname,
    lastname: user?.lastname,
    email: user?.email,
    userType: user?.userType,
  }

  const validationSchema = Yup.object({
    firstname: Yup.string().required("Please Enter Your Firstname"),
    lastname: Yup.string().required("Please Enter Your Lastname"),
    email: Yup.string().required("Please Enter a valid email"),
  })

  var fields = [
    {
      fieldName: "firstname",
      label: "Firstname",
    },
    {
      fieldName: "lastname",
      label: "Lastname",
    },
  ]

  const handleSubmit = async values => {
    try {
      setLoading(true)
      await UpdateUser(values)
      displayMessage("Saved successfully!", setSuccess)
    } catch (error) {
      if (error?.response?.status === 400)
        displayMessage(error.response.data, setError)
      else displayMessage("An unexpected error happened!", setError)
      console.error(error)
    }
    setLoading(false)
  }

  const handleChangeEmail = async values => {
    try {
      setLoading(true)
      await ChangeEmail(values)
      displayMessage(
        "Please check your inbox and confirm your new email address!",
        setSuccess
      )
    } catch (error) {
      if (error?.response?.status === 400)
        displayMessage(error.response.data, setError)
      else displayMessage("An unexpected error happened!", setError)
      console.error(error)
    }
    setLoading(false)
  }

  return (
    <div>
      <h5 className="mb-4">Personal Details</h5>
      <Row>
        <Col lg="6" className="my-4">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <Form>
                {fields.map((field, key) => (
                  <FormikInputField
                    fieldName={field.fieldName}
                    label={field.label}
                    errors={errors}
                    touched={touched}
                    key={key}
                  />
                ))}
                <button type="submit" className="btn btn-primary mb-0">
                  Save
                </button>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
      <Row>
        <Col lg="6" className="my-4">
          <h6 className="mb-3">Change Email address:</h6>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleChangeEmail}
          >
            {({ errors, touched }) => (
              <Form>
                <FormikInputField
                  fieldName="email"
                  label="Email"
                  type="email"
                  errors={errors}
                  touched={touched}
                  className="w-75"
                />
                <button type="submit" className="btn btn-primary">
                  Change Email
                </button>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  )
}

export default PersonalSettingsTab
