import React, { useEffect, useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
  TabContent,
} from "reactstrap"
import classnames from "classnames"
import { Link } from "react-router-dom"
import classNames from "classnames"
import AccountTypeTab from "./AccountTypeTab"
import CompanyInfoTab from "./CompanyInfoTab"
import LocationsTab from "./LocationsTab"
import ConfirmationTab from "./ConfirmationTab"
import LogoUploadTab from "./LogoUploadTab"
import PricingTab from "./PricingTab"

const RegisterWizard = props => {
  const [activeTab, setactiveTab] = useState(1)
  const [passedSteps, setPassedSteps] = useState([1])
  const [company, setCompany] = useState()

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col>
              <Card className="overflow-hidden">
                <CardBody className="">
                  <h4 className="card-title mb-4">Registration</h4>
                  <div className="wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classNames({ current: activeTab === 1 })}
                        >
                          <NavLink
                            className={classnames({ current: activeTab === 1 })}
                            onClick={() => {
                              setactiveTab(1)
                            }}
                            disabled={!(passedSteps || []).includes(1)}
                          >
                            <span className="number">1.</span> Details
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 2 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 2 })}
                            onClick={() => {
                              setactiveTab(2)
                            }}
                            disabled={!(passedSteps || []).includes(2)}
                          >
                            <span className="number">2.</span> Address
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 3 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 3 })}
                            onClick={() => {
                              setactiveTab(3)
                            }}
                            disabled={!(passedSteps || []).includes(3)}
                          >
                            <span className="number">3.</span> Logo
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 4 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 4 })}
                            onClick={() => {
                              setactiveTab(4)
                            }}
                            disabled={!(passedSteps || []).includes(4)}
                          >
                            <span className="number">4.</span> Locations
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 5 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 5 })}
                            onClick={() => {
                              setactiveTab(5)
                            }}
                            disabled={!(passedSteps || []).includes(4)}
                          >
                            <span className="number">5.</span> Pricing
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 6 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 6 })}
                            onClick={() => {
                              setactiveTab(6)
                            }}
                            disabled={!(passedSteps || []).includes(6)}
                          >
                            <span className="number">6.</span> Confirm Detail
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                    <div className="content clearfix">
                      <TabContent activeTab={activeTab} className="body">
                        <AccountTypeTab
                          nextTab={_ => toggleTab(activeTab + 1)}
                        />

                        <CompanyInfoTab
                          nextTab={_ => toggleTab(activeTab + 1)}
                          setCompany={setCompany}
                        />

                        <LogoUploadTab
                          nextTab={_ => toggleTab(activeTab + 1)}
                          company={company}
                        />

                        <PricingTab
                          nextTab={_ => toggleTab(activeTab + 1)}
                          company={company}
                        />

                        <LocationsTab
                          nextTab={_ => toggleTab(activeTab + 1)}
                          company={company}
                        />

                        <ConfirmationTab />
                      </TabContent>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default RegisterWizard
