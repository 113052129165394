import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { isEmpty } from "lodash"
import { Col, Container, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import Activity from "./Activity"
import Overview from "./Overview"
import QCComments from "./QCComments"

//redux

const QCOverview = props => {
  const [projectDetail, setProjectDetail] = useState({
    price: 1200,
    quantity: 100,
    partNumber: "test1234",
    manufacturer: "infineon",
    internalPart: "test12345",
    currency: "€",
    company: {
      name: "JUST4ONLINE",
      postalCode: 9220,
      city: "Velden am Wörthersee",
      region: "Europe",
    },
    files: [
      { name: "QC Offer", size: "3.25 Mb" },
      { name: "report 2", size: "1.02 Mb" },
    ],
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Details" breadcrumbItem="Quality Check" />

          {!isEmpty(projectDetail) && (
            <>
              <Row>
                <Col lg="4">
                  <Overview part={projectDetail} />
                </Col>
                <Col lg="4">
                  <Activity />
                </Col>
                <Col lg="4">
                  <QCComments />
                </Col>
                {/* <Col lg="4">
                  <AttachedFiles files={projectDetail.files} />
                </Col> */}
              </Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

QCOverview.propTypes = {
  match: PropTypes.object,
}

export default withRouter(QCOverview)
