import React from "react"
import countries from "i18n-iso-countries"
import enLocale from "i18n-iso-countries/langs/en.json"
import moment from "moment"
import { jwtDecode } from "jwt-decode"
import { GetUnreadMessages } from "./backend_helper"
import { useUnreadMessagesCount } from "./custom_hooks"

export const displayMessage = (message, setMessage) => {
  setMessage(message)

  setTimeout(() => {
    setMessage()
  }, 3000)
}

export const getCountryList = () => {
  countries.registerLocale(enLocale)
  const countryObj = countries.getNames("en")

  return Object.entries(countryObj).map(([key, value]) => {
    return { code: key, name: value }
  })
}

export class UserInfoError extends Error {
  constructor(msg) {
    super(msg)
  }
}

export const currencyFormatter = new Intl.NumberFormat("de-DE", {
  style: "currency",
  currency: "EUR",
  minimumFractionDigits: 4, // specifies the minimum number of decimal digits
  maximumFractionDigits: 4, // specifies the maximum number of decimal digits
})

export const formatDate = date => {
  return moment(date).format("DD.MM.YYYY HH:mm")
}

export const IsAdminUser = _ => {
  try {
    const authUser = localStorage.getItem("authUser")

    if (authUser) {
      const user = jwtDecode(authUser)

      return user.userType == "admin"
    } else return false
  } catch (error) {
    console.log(error)
    return false
  }
}

export const IsPremiumUser = _ => {
  try {
    const authUser = localStorage.getItem("authUser")

    if (authUser) {
      const user = jwtDecode(authUser)

      return user.accountType == "Premium"
    } else return false
  } catch (error) {
    console.log(error)
    return false
  }
}

export const GetLoggedOnUser = _ => {
  try {
    const authUser = localStorage.getItem("authUser")

    if (authUser) return jwtDecode(authUser)
  } catch (error) {
    console.log(error)
  }
}

export const getRequestStatusColor = status => {
  switch (status) {
    case "accepted":
      return "badge-soft-primary"
    case "declined":
      return "badge-soft-danger"
    case "RFQ":
      return "badge-soft-info"
    case "archived":
      return "badge-soft-dark"
    default:
      return "" // Default class or leave empty
  }
}

export const updateCountUnreadMessages = async setCountMessages => {
  const messages = await GetUnreadMessages()

  setCountMessages(messages.length)
}
