import React, { useEffect, useState } from "react"

const NumberInput = ({ initial, onChange, maxWidth, classNames, onBlur }) => {
  const [value, setValue] = useState("" + initial)
  const [error, setError] = useState(false)
  const [isFocused, setIsFocused] = useState(false)

  useEffect(() => {
    setValue("" + initial)
  }, [initial])

  const handleTextChange = e => {
    let newVal = e.target.value
    newVal = newVal.replace(",", ".")
    setValue(newVal)
    const result = parseFloat(newVal)

    if (isNaN(result)) return setError(true)

    setError(false)
    onChange(result)
  }

  return (
    <input
      className={"form-control  " + classNames + (error ? " is-invalid" : "")}
      style={{ maxWidth: maxWidth ?? "auto" }}
      type="text"
      value={!isFocused && value == 0 ? "" : value}
      onChange={handleTextChange}
      onBlur={_ => setIsFocused(false)}
      onFocus={_ => setIsFocused(true)}
    />
  )
}

export default NumberInput
