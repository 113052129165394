import React, { useState } from "react"
import { Modal, ModalBody } from "reactstrap"
import { Rating } from "react-simple-star-rating"
import { displayMessage } from "helpers/misc_helpers"
import { useLoading } from "helpers/custom_hooks"
import { CreateRating } from "helpers/backend_helper"

const RatingModal = ({ request, show, onCancel }) => {
  const [rating, setRating] = useState(0)
  const [error, setError] = useState()
  const setLoading = useLoading()

  const handleSubmit = async () => {
    try {
      setLoading(true)
      await CreateRating({
        requestID: request.id,
        rating,
      })
      window.location.reload()
    } catch (error) {
      setLoading(false)
      displayMessage("An unexpected error happened!", setError)
    }
  }

  return (
    <Modal size="sm" isOpen={show} toggle={onCancel} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <button
            type="button"
            onClick={onCancel}
            className="btn-close position-absolute end-0 top-0 m-3"
          ></button>

          <p className="text-muted font-size-16 mb-3">
            Please provide feedback on your partner's performance
          </p>

          <Rating
            onClick={rate => setRating(rate)}
            fillClassName="text-primary"
          />

          <div className="hstack gap-2 justify-content-center mb-0 mt-4">
            <button
              type="button"
              className="btn btn-outline-secondary w-100"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-outline-primary w-100"
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default RatingModal
