import React, { useEffect, useState } from "react"
import { Col, Row, Form } from "reactstrap"
import {
  IsPremiumUser,
  displayMessage,
  getCountryList,
} from "helpers/misc_helpers"
import { GetMyLocations, UpdateLocations } from "helpers/backend_helper"
import { useShowLicenseModal } from "helpers/custom_hooks"

const LocationsSettingsTab = ({ user, setLoading, setError, setSuccess }) => {
  const [locations, setLocations] = useState([])
  const showLicenseModal = useShowLicenseModal()
  const countryList = getCountryList()

  const onAddFormRow = () => {
    if (!IsPremiumUser()) return showLicenseModal(true)
    const modifiedRows = [...locations]
    modifiedRows.push({
      index: modifiedRows.length + 1,
      companyID: user?.company?.id,
    })
    setLocations(modifiedRows)
  }

  const onDeleteFormRow = id => {
    var modifiedRows = [...locations]
    modifiedRows = modifiedRows.filter(x => x["index"] !== id)
    setLocations(modifiedRows)
  }

  const handleSubmit = async e => {
    try {
      e.preventDefault()
      setLoading(true)
      const newLocations = locations.filter(l => l.index > 0)

      await UpdateLocations(newLocations)
      displayMessage("Saved successfully!", setSuccess)
    } catch (error) {
      if (error?.response?.status === 400)
        displayMessage(error.response.data, setError)
      else displayMessage("An unexpected error happened!", setError)
      console.error(error)
    }
    setLoading(false)
  }

  useEffect(
    _ => {
      loadData()
    },
    [user.company]
  )

  const loadData = async () => {
    setLoading(true)
    locations.splice(0, 1)

    const result = await GetMyLocations()

    result.forEach((element, index) => {
      element.index = index
    })

    setLocations(result)
    setLoading(false)
  }

  return (
    <div>
      <h6 className="mb-4 card-title">Locations:</h6>
      <Form className="repeater" encType="multipart/form-data">
        <div>
          {locations.map((location, key) => (
            <Row key={key} className="mb-4 mt-4">
              <Col lg="10">
                <Row>
                  <Col className="mb-3">
                    <label htmlFor="name">Street:</label>
                    <input
                      type="text"
                      className="form-control"
                      disabled={key === 0}
                      value={location?.street ?? ""}
                      onChange={e => {
                        locations[key].street = e.target.value
                        setLocations([...locations])
                      }}
                    />
                  </Col>
                  <Col className="mb-3">
                    <label htmlFor="email">Postal Code:</label>
                    <input
                      type="text"
                      className="form-control"
                      disabled={key === 0}
                      value={location?.postalCode ?? ""}
                      onChange={e => {
                        locations[key].postalCode = e.target.value
                        setLocations([...locations])
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-3">
                    <label htmlFor="email">City:</label>
                    <input
                      type="text"
                      className="form-control"
                      disabled={key === 0}
                      value={location?.city ?? ""}
                      onChange={e => {
                        locations[key].city = e.target.value
                        setLocations([...locations])
                      }}
                    />
                  </Col>
                  <Col className="mb-3">
                    <label htmlFor="email">Country:</label>
                    <select
                      className="form-select"
                      disabled={key === 0}
                      value={location?.country ?? ""}
                      onChange={e => {
                        locations[key].country = e.target.value
                        setLocations([...locations])
                      }}
                    >
                      <option disabled value="">
                        -
                      </option>
                      {countryList.map((value, key) => (
                        <option key={key} value={value.code}>
                          {value.name}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-3" lg={6}>
                    <label>Region:</label>
                    <select
                      className="form-select"
                      name="region"
                      value={location?.region ?? ""}
                      disabled={key === 0}
                      onChange={e => {
                        locations[key].region = e.target.value
                        setLocations([...locations])
                      }}
                    >
                      <option disabled value="">
                        -
                      </option>
                      <option value="Africa">Africa</option>
                      <option value="Asia">Asia</option>
                      <option value="Australia">Australia</option>
                      <option value="Europe">Europe</option>
                      <option value="North America">North America</option>
                      <option value="South America">South America</option>
                    </select>
                  </Col>
                </Row>
              </Col>
              <Col className="align-self-end mb-3">
                <div className="d-grid">
                  {key !== 0 && (
                    <input
                      type="button"
                      className="btn btn-primary"
                      value="Delete"
                      onClick={() => onDeleteFormRow(key)}
                    />
                  )}
                </div>
              </Col>
            </Row>
          ))}
        </div>

        <input
          type="button"
          className="btn btn-primary mt-3 mt-lg-0"
          value="Add new Location"
          onClick={() => onAddFormRow()}
        />
        <div className="d-flex justify-content-end">
          <button
            type="submit"
            className="btn btn-primary"
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>
      </Form>
    </div>
  )
}

export default LocationsSettingsTab
