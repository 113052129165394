import { GlobalContext } from "App"
import { useContext } from "react"

export const useLoading = () => {
  const { setLoading } = useContext(GlobalContext)
  return setLoading
}

export const useUnreadMessagesCount = () => {
  const { unreadMessagesCount, setUnreadMessagesCount } =
    useContext(GlobalContext)
  return [unreadMessagesCount, setUnreadMessagesCount]
}

export const useShowLicenseModal = () => {
  const { setShowLicenceModal } = useContext(GlobalContext)
  return setShowLicenceModal
}
