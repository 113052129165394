import { Field, Form, Formik } from "formik"
import FormikInputField from "helpers/FormikInputField"
import { UpdatePartList, UpdateWatchlistPart } from "helpers/backend_helper"
import { useLoading } from "helpers/custom_hooks"
import * as Yup from "yup"
import React, { useState } from "react"
import { Alert, Col, Label, Modal, ModalBody, Row } from "reactstrap"
import { values } from "lodash"
import { displayMessage } from "helpers/misc_helpers"
import FormikPriceInputField from "helpers/FormikPriceInputField"

const fields = [
  {
    fieldName: "internalPart",
    label: "IPN",
    component: FormikInputField,
  },
  {
    fieldName: "partNumber",
    label: "Manufacturer Part Number",
    component: FormikInputField,
  },
  {
    fieldName: "manufacturer",
    label: "Manufacturer",
    component: FormikInputField,
  },
  {
    fieldName: "price",
    label: "Target Price",
    component: FormikPriceInputField,
  },
  {
    fieldName: "quantity",
    label: "Quantity",
    component: FormikInputField,
  },
  {
    fieldName: "description",
    label: "Description",
    component: FormikInputField,
  },
  {
    fieldName: "notes",
    label: "Notes",
    component: FormikInputField,
  },
]

const PartModal = ({ onClose, onSuccess, part }) => {
  const setLoading = useLoading()
  const [error, setError] = useState()
  const initialValues = {
    id: part?.id,
    internalPart: part?.internalPart ?? "",
    currency: part?.currency ?? "",
    isHighrunner: part?.isHighrunner ?? "",
    manufacturer: part?.manufacturer ?? "",
    notes: part?.notes ?? "",
    partNumber: part?.partNumber ?? "",
    quantity: part?.quantity ?? "",
    price: part?.price ?? "",
    type: part?.type ?? "",
    description: part?.description ?? "",
  }

  const handleSubmit = async values => {
    try {
      const price = parseFloat(("" + values.price)?.replace(",", "."))
      const qty = parseInt(values.quantity)

      if ((isNaN(price) && values.price) || isNaN(qty))
        return displayMessage("Invalid Number Format!", setError)

      setLoading(true)

      await UpdatePartList({
        ...values,
        price: isNaN(price) ? undefined : price,
        quantity: qty,
      })
      onSuccess()
    } catch (error) {
      error?.response?.status == 400
        ? displayMessage(error.response.data, setError)
        : displayMessage("An unhandled exception happend!", setError)
      setLoading(false)
    }
  }

  return (
    <Modal size="md" isOpen={true} toggle={onClose} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 ">
          <button
            type="button"
            onClick={onClose}
            className="btn-close position-absolute end-0 top-0 m-3"
          ></button>
          <div className="avatar-sm mb-4 mx-auto">
            <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
              <i className="mdi mdi-file-document"></i>
            </div>
          </div>
          {error ? <Alert color="danger">{error}</Alert> : null}
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ errors, touched }) => (
              <Form>
                <Row>
                  {fields.map((field, key) => (
                    <Col lg="6" key={key}>
                      <field.component
                        fieldName={field.fieldName}
                        label={field.label}
                        errors={errors}
                        touched={touched}
                      />
                    </Col>
                  ))}

                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-cstno-input7">Type:</Label>
                      <Field as="select" className="form-select" name="type">
                        <option disabled value="">
                          -
                        </option>
                        <option value="APQ">APQ</option>
                        <option value="Excess">Excess</option>
                      </Field>
                      {errors.type && touched.type && (
                        <div className="text-danger">{errors.type}</div>
                      )}
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-cstno-input7">Is Highrunner:</Label>
                      <Field
                        as="select"
                        className="form-select"
                        name="isHighrunner"
                      >
                        <option disabled value="">
                          -
                        </option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </Field>
                      {errors.isHighrunner && touched.isHighrunner && (
                        <div className="text-danger">{errors.isHighrunner}</div>
                      )}
                    </div>
                  </Col>
                </Row>

                <div className="d-flex justify-content-end">
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default PartModal
