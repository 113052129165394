import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"

export const LoginUser = data => post(url.LOGIN, data)

export const RegisterUser = data => post(url.REGISTER, data)

export const ConfirmEmail = token => post(url.CONFIRM_EMAIL + "/" + token)

export const CreateUser = data => post(url.USERS, data)

export const UpdateUser = data => put(url.USERS, data)

export const ResetPassword = data => post(url.RESET_PASSWORD, data)

export const ChangePassword = (data, token) =>
  post(url.RESET_PASSWORD + "/" + token, data)

export const ChangeEmail = data => post(url.CHANGE_EMAIL, data)

export const ChangeEmailConfirmation = token =>
  post(url.CHANGE_EMAIL_CONFIRMATION + "/" + token)

export const DeleteUser = id => del(url.DELETE_USER + id)

export const DeleteMyAccount = id => del(url.MY_USER)

export const GetUsers = _ => get(url.USERS)

export const GetMyUser = _ => get(url.MY_USER)

export const CreateCompany = data => post(url.COMPANIES, data)

export const UpdateCompany = data => put(url.COMPANIES, data)

export const UpdateBilling = data => put(url.BILLING, data)

export const RequestUpgrade = data => post(url.UPGRADE, data)

export const RequestDowngrade = _ => post(url.DOWNGRADE)

export const UpdateLogo = data => put(url.UPDATELOGO, data)

export const CreateLocations = data => post(url.LOCATIONS, data)

export const UpdateLocations = data => put(url.LOCATIONS, data)

export const UploadPartList = data => post(url.PART_LIST, data)

export const UpdatePartList = data => put(url.PART_LIST, data)

export const DeletePart = id => del(url.PART_LIST + "/" + id)

export const GetParts = search => get(url.PART_LIST + "?search=" + search)

export const GetMyParts = search => get(url.MY_PART_LIST)

export const GetPartByID = partID => get(url.PART_LIST + "/" + partID)

export const UploadLogo = file => post(url.UPLOAD_LOGOS, file)

export const UploadChatAttachment = file =>
  post(url.UPLOAD_CHATATTACHMENTS, file)

export const GetMyLocations = _ => get(url.MY_LOCATIONS)

export const GetLocationByID = id => get(url.MY_LOCATIONS + "/" + id)

export const CreateRequest = data => post(url.REQUESTS, data)

export const AcceptRequest = data => post(url.REQUESTS + "/accept", data)

export const DeclineRequest = data => post(url.REQUESTS + "/decline", data)

export const ArchiveRequest = data => post(url.REQUESTS + "/archive", data)

export const GetMyRequests = data => get(url.REQUESTS, data)

export const CreateMessage = data => post(url.REQUESTS + "/messages", data)

export const MarkMessageAsRead = id =>
  put(url.REQUESTS + "/messages/markasread/" + id)

export const GetUnreadMessages = id => get(url.REQUESTS + "/messages/unread")

export const SavePart = partID => post(url.SAVEDPARTS, { partID })

export const GetSavedParts = _ => get(url.SAVEDPARTS)

export const UploadWatchlist = data => post(url.WATCH_LIST, data)

export const UpdateWatchlistPart = data => put(url.WATCH_LIST, data)

export const DeleteWatchlistPart = id => del(url.WATCH_LIST + "/" + id)

export const GetWatchlist = _ => get(url.WATCH_LIST)

export const CreateRating = data => post(url.RATINGS, data)

export const GetRatingFromComapny = id => get(url.RATINGS + "/" + id)
