import React, { useState } from "react"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap"
// Formik validation
import * as Yup from "yup"
import { Formik, Form } from "formik"
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo-sm.png"
import { useLoading } from "helpers/custom_hooks"
import { ChangePassword } from "helpers/backend_helper"
import FormikInputField from "helpers/FormikInputField"
import { displayMessage } from "helpers/misc_helpers"
import FormikPasswordField from "helpers/FormikPasswordField"

const ResetPassword = () => {
  const [queryParameters] = useSearchParams()
  const token = queryParameters.get("token")
  const setLoading = useLoading()
  const navigate = useNavigate()
  const [error, setError] = useState()
  const [success, setSuccess] = useState()

  const initialValues = {
    password: "",
  }

  const validationSchema = Yup.object({
    password: Yup.string().required("Please Enter Your Password"),
  })

  const handleSubmit = async values => {
    try {
      setLoading(true)
      await ChangePassword(values, token)
      setSuccess(true)
      setTimeout(() => {
        navigate("/login")
      }, 4000)
    } catch (err) {
      setLoading(false)

      err?.response?.status == 400
        ? displayMessage(err.response.data, setError)
        : displayMessage("An unhandled exception happend!", setError)
    }
    setLoading(false)
  }

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Password Reset !</h5>
                        <p></p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                    >
                      {({ errors, touched }) => (
                        <Form>
                          {error ? <Alert color="danger">{error}</Alert> : null}
                          {success ? (
                            <Alert color="success">
                              Password reset successfully. You will get
                              redirected shortly.{" "}
                            </Alert>
                          ) : null}

                          <FormikPasswordField
                            fieldName="password"
                            fieldType="password"
                            label="Password"
                            errors={errors}
                            touched={touched}
                          />

                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-primary btn-block"
                              type="submit"
                            >
                              Change Password
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>© {new Date().getFullYear()} chipsconnect</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ResetPassword
