import React, { useState } from "react"
import { Col, Row } from "reactstrap"
import { displayMessage } from "helpers/misc_helpers"
import { useNavigate } from "react-router-dom"
import DeleteModal from "components/Common/DeleteModal"
import { DeleteMyAccount } from "helpers/backend_helper"

const DeleteAccountTab = ({ user, setLoading, setError }) => {
  const [deleteModal, setDeleteModal] = useState()
  const navigate = useNavigate()

  const handleDelete = async _ => {
    try {
      setLoading(true)
      await DeleteMyAccount()
      navigate("/logout")
    } catch (error) {
      if (error?.response?.status === 400)
        displayMessage(error.response.data, setError)
      else displayMessage("An unexpected error happened!", setError)
      console.error(error)
    }
    setLoading(false)
  }

  return (
    <div>
      <h5 className="mb-4">Security</h5>
      <Row>
        <Col lg="8" className="my-4">
          <h6 className="mb-3">Account Deletion:</h6>
          <p>
            If you want to delete your account, click the button below. <br />
          </p>
          <button
            className="btn btn-danger"
            onClick={_ => setDeleteModal(true)}
          >
            Delete Account
          </button>
        </Col>
      </Row>
      <DeleteModal
        show={deleteModal}
        onCloseClick={_ => setDeleteModal(false)}
        onDeleteClick={handleDelete}
        displayMessage={
          "Are you sure you want to permanently delete your account? "
        }
      />
    </div>
  )
}

export default DeleteAccountTab
