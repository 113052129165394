import React, { useEffect, useRef, useState } from "react"
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  InputGroup,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import "react-perfect-scrollbar/dist/css/styles.css"
import {
  IsPremiumUser,
  displayMessage,
  updateCountUnreadMessages,
} from "helpers/misc_helpers"
import { useLoading, useUnreadMessagesCount } from "helpers/custom_hooks"
import {
  AcceptRequest,
  CreateMessage,
  DeclineRequest,
  GetMyRequests,
  GetMyUser,
  MarkMessageAsRead,
  UploadChatAttachment,
} from "helpers/backend_helper"
import moment from "moment"
import header from "../../assets/images/world-icon.png"
import RequestList from "./RequestList"
import DeclineModal from "./DeclineModal"
import ChatHeader from "./ChatHeader"

const Requests = () => {
  const [currentChat, setCurrentChat] = useState()
  const [error, setError] = useState()
  const setLoading = useLoading()
  const [user, setUser] = useState()
  const [requests, setRequests] = useState([])
  const [filteredRequests, setFilteredRequests] = useState([])
  const [declineModal, setDeclineModal] = useState(false)
  const lastMessage = currentChat?.messages[currentChat.messages.length - 1]
  const [selectedStatus, setSelectedStatus] = useState("")
  const [company, setCompany] = useState()
  const scrollRef = useRef(null)
  const [_, setCountMessages] = useUnreadMessagesCount()

  const [newMessage, setNewMessage] = useState({
    deliveryDate: new Date(),
    price: "",
    quantity: "",
    message: "",
  })

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    setLoading(true)
    const result = await GetMyUser()
    let reqList = await GetMyRequests()
    reqList = sortRequests(reqList)
    setRequests(reqList)
    setFilteredRequests(reqList)
    setUser(result)
    setLoading(false)
  }

  const sortRequests = req => {
    req.sort(
      (a, b) =>
        Math.max(...b.messages.map(m => m.id)) -
        Math.max(...a.messages.map(m => m.id))
    )
    return req
  }

  const sendMessage = async () => {
    try {
      setLoading(true)
      let result

      //todo only allow price, quantity and deliverydate together

      if (newMessage.file) {
        const formData = new FormData()
        formData.append("file", newMessage.file)
        formData.append("id", currentChat.id)
        result = await UploadChatAttachment(formData)
      }

      const reqObj = getReqObj(newMessage, result)

      await CreateMessage(reqObj)
      loadData()
    } catch (error) {
      console.log(error)
      error?.response?.status == 400
        ? displayMessage(error.response.data, setError)
        : displayMessage("An unexpected error happened!", setError)
      setLoading(false)
    }
  }

  const getReqObj = (newMessage, file) => {
    let reqObj = {
      requestID: currentChat.id,
      fileURL: file?.url,
    }

    return {
      ...reqObj,
      ...newMessage,
      deliveryDate: newMessage.deliveryDate
        ? moment(newMessage.deliveryDate).format("YYYY-MM-DD")
        : undefined,
      price: newMessage.price
        ? newMessage.price.replace(",", ".")
        : newMessage.price,
    }
  }

  const handleAccept = async () => {
    setLoading(true)
    await AcceptRequest({ requestID: currentChat.id })
    loadData()
  }
  const handleDecline = async comment => {
    setLoading(true)
    await DeclineRequest({ requestID: currentChat.id, comment })
    setDeclineModal(false)
    loadData()
  }

  useEffect(() => {
    if (requests?.length > 0) setCurrentChat(requests[0])
  }, [requests])

  useEffect(() => {
    if (currentChat) {
      currentChat.messages.sort(function (a, b) {
        return b.id - a.id // Sorting in descending order
      })
      setCompany(getCompanyInfo())

      //mark message as read
      const unreadMessages = currentChat.messages.filter(
        m => !m.readAt && m.companyID != user.company.id
      )

      if (unreadMessages.length > 0) {
        unreadMessages.forEach(m => MarkMessageAsRead(m.id))

        unreadMessages.forEach(m => {
          requests.forEach(request => {
            request.messages.forEach(message => {
              if (message.id === m.id) {
                message.readAt = new Date()
              }
            })
          })
        })

        setRequests(requests)
        setTimeout(() => {
          updateCountUnreadMessages(setCountMessages)
        }, 1000)
      }
    }
  }, [currentChat])

  useEffect(() => {
    setNewMessage({
      price: "",
      quantity: "",
      deliveryDate: "",
      currency: currentChat?.currency,
    })
  }, [lastMessage])

  useEffect(() => {
    if (scrollRef.current) {
      const scrollContainer = scrollRef.current
      scrollContainer.scrollTop = scrollContainer.scrollHeight
    }
  }, [lastMessage])

  const isSender = message => message?.companyID === user?.company?.id

  const handleFileChange = event => {
    event.preventDefault()
    let file = event.target.files[0]
    setNewMessage({ ...newMessage, file: file })
    event.target.value = ""
  }

  useEffect(() => {
    if (selectedStatus == "") return setFilteredRequests(requests)

    return setFilteredRequests(
      requests.filter(
        r =>
          (r.status == selectedStatus && !r.isArchived) ||
          (selectedStatus == "archived" && r.isArchived)
      )
    )
  }, [selectedStatus])

  const getCompanyInfo = chat => {
    const obj = chat ?? currentChat

    if (user.company.id == obj?.seller?.id) return obj.buyer
    return obj.seller
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {error && <Alert color="danger">{error}</Alert>}
          {requests?.length == 0 && (
            <Card>
              <CardBody>
                <img src={header} height="200px" className="mx-auto d-block" />
                <h4 className="text-center mt-4">No requests yet</h4>
                <p className="text-muted text-center">
                  Your company has no incoming or outgoing requests currently.{" "}
                  <br /> Create a request via the part search. For incoming
                  requests you will receive an email. <br />
                </p>
                <p className="text-muted text-center">
                  {" "}
                  For additional guidance, we invite you to watch our
                  instructional video{" "}
                  <a
                    href="https://chipsconnect.com/instruction-videos/Request-process.mp4"
                    target="_blank"
                    rel="noreferrer"
                  >
                    here.
                  </a>
                </p>
              </CardBody>
            </Card>
          )}
          {requests?.length > 0 && (
            <>
              <Row>
                <Col lg="12">
                  <div className="d-lg-flex justify-content-center align-items-start">
                    <RequestList
                      user={user}
                      setSelectedStatus={setSelectedStatus}
                      selectedStatus={selectedStatus}
                      getCompanyInfo={getCompanyInfo}
                      requests={filteredRequests}
                      currentChat={currentChat}
                      setCurrentChat={setCurrentChat}
                      isSender={isSender}
                    />
                    <div className="w-100 ">
                      <Card>
                        {currentChat && (
                          <>
                            <ChatHeader chat={currentChat} company={company} />

                            <div>
                              <div className="chat-conversation p-3">
                                <ul className="list-unstyled">
                                  <div
                                    ref={scrollRef}
                                    style={{
                                      height: "50vh",
                                      overflowY: "auto",
                                    }}
                                    className="pe-2 pb-2"
                                  >
                                    {currentChat?.messages &&
                                      currentChat?.messages
                                        .sort((a, b) => a.id - b.id)
                                        .map(message => (
                                          <li
                                            key={"test_k" + message?.id}
                                            className={
                                              isSender(message) ? "right" : ""
                                            }
                                          >
                                            <div className="conversation-list d-flex align-items-center">
                                              <div
                                                className={
                                                  "ctext-wrap " +
                                                  (isSender(message)
                                                    ? "bg-primary bg-soft"
                                                    : "bg-light")
                                                }
                                              >
                                                <div className="conversation-name"></div>
                                                {message.deliveryDate && (
                                                  <p
                                                    style={{
                                                      marginBottom: "0.5rem",
                                                    }}
                                                  >
                                                    <b>Delivery Date:</b>{" "}
                                                    {moment(
                                                      message.deliveryDate
                                                    ).format("DD.MM.YYYY")}
                                                  </p>
                                                )}
                                                {message?.quantity && (
                                                  <p
                                                    style={{
                                                      marginBottom: "0.5rem",
                                                    }}
                                                  >
                                                    <b>Quantity:</b>{" "}
                                                    {message.quantity} pc.
                                                  </p>
                                                )}

                                                {message.price > 0 && (
                                                  <>
                                                    <p
                                                      style={{
                                                        marginBottom: "0.5rem",
                                                      }}
                                                    >
                                                      <b>Price:</b>{" "}
                                                      {message?.price.toFixed(
                                                        4
                                                      ) +
                                                        " " +
                                                        message?.currency}
                                                    </p>
                                                    <p
                                                      style={{
                                                        marginBottom: "0.5rem",
                                                      }}
                                                    >
                                                      <b>Total:</b>{" "}
                                                      {(
                                                        message.price *
                                                        message.quantity
                                                      ).toFixed(2) +
                                                        " " +
                                                        message?.currency}
                                                    </p>{" "}
                                                  </>
                                                )}
                                                <p
                                                  style={{
                                                    marginBottom: "0.5rem",
                                                  }}
                                                >
                                                  {message.message
                                                    ?.split("<br/>")
                                                    .map(
                                                      (line, index, array) => (
                                                        <React.Fragment
                                                          key={index}
                                                        >
                                                          {line}
                                                          {index !==
                                                            array.length -
                                                              1 && <br />}
                                                        </React.Fragment>
                                                      )
                                                    )}
                                                </p>

                                                {message.fileURL && (
                                                  <Row className="justify-content-end">
                                                    <Col className="col-auto px-0">
                                                      <i className="bx bxs-file ms-1 font-size-18" />
                                                    </Col>
                                                    <Col className="col-auto ">
                                                      <a
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        href={message.fileURL}
                                                        className="text-decoration-underline"
                                                      >
                                                        {new URL(
                                                          message.fileURL
                                                        ).pathname
                                                          .split("/")
                                                          .pop()}
                                                      </a>
                                                    </Col>
                                                  </Row>
                                                )}
                                                <p className="chat-time mb-0">
                                                  <i className="bx bx-time-five align-middle me-1"></i>{" "}
                                                  {moment(message.date).format(
                                                    "DD.MM.YY HH:mm"
                                                  )}{" "}
                                                  {message.username
                                                    ? " - " + message.username
                                                    : ""}
                                                </p>
                                              </div>
                                              {!isSender(message) &&
                                                message == lastMessage &&
                                                currentChat.status == "RFQ" &&
                                                !currentChat.isArchived && (
                                                  <div>
                                                    <button
                                                      className="btn btn-soft-primary ms-2"
                                                      onClick={handleAccept}
                                                    >
                                                      Accept Request
                                                    </button>
                                                    <button
                                                      className="btn btn-soft-danger ms-2"
                                                      onClick={_ =>
                                                        setDeclineModal(true)
                                                      }
                                                    >
                                                      Decline Request
                                                    </button>
                                                  </div>
                                                )}
                                            </div>
                                          </li>
                                        ))}
                                  </div>
                                </ul>
                              </div>

                              {currentChat.status == "RFQ" &&
                                !currentChat.isArchived && (
                                  <div className="p-3 chat-input-section">
                                    <Row>
                                      <p className="text-muted">
                                        If you do not accept the request, you
                                        are able to send a counteroffer here:
                                      </p>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <Label className="ps-2">Price:</Label>
                                        <div className="position-relative">
                                          <InputGroup>
                                            <input
                                              type="text"
                                              onChange={e =>
                                                setNewMessage({
                                                  ...newMessage,
                                                  price: e.target.value,
                                                })
                                              }
                                              className="form-control chat-input ps-3 pe-3"
                                              placeholder="Enter price..."
                                              value={newMessage?.price ?? ""}
                                            />
                                            <select
                                              type="text"
                                              style={{ maxWidth: "75px" }}
                                              onChange={e =>
                                                setNewMessage({
                                                  ...newMessage,
                                                  currency: e.target.value,
                                                })
                                              }
                                              className="form-select chat-input ps-3 pe-3"
                                              placeholder="Enter price..."
                                              value={newMessage?.currency ?? ""}
                                            >
                                              <option value="EUR">EUR</option>
                                              <option value="USD">USD</option>
                                              <option value="GBP">GBP</option>
                                              <option value="YEN">YEN</option>
                                            </select>
                                          </InputGroup>
                                        </div>
                                      </Col>
                                      <Col>
                                        <Label className="ps-2">
                                          Quantity:
                                        </Label>
                                        <div className="position-relative">
                                          <input
                                            type="text"
                                            onChange={e =>
                                              setNewMessage({
                                                ...newMessage,
                                                quantity: e.target.value,
                                              })
                                            }
                                            className="form-control chat-input ps-3 pe-3"
                                            placeholder="Enter quantity..."
                                            value={newMessage?.quantity ?? ""}
                                          />
                                        </div>
                                      </Col>
                                      <Col>
                                        <Label className="ps-2">
                                          Delivery Date:
                                        </Label>
                                        <div className="position-relative">
                                          <input
                                            type="date"
                                            onChange={e => {
                                              setNewMessage({
                                                ...newMessage,
                                                deliveryDate: moment(
                                                  e.target.value
                                                ).format("YYYY-MM-DD"),
                                              })
                                            }}
                                            min={
                                              new Date()
                                                .toISOString()
                                                .split("T")[0]
                                            }
                                            value={moment(
                                              newMessage?.deliveryDate ??
                                                new Date()
                                            ).format("YYYY-MM-DD")}
                                            className="form-control chat-input pe-2 ps-3 pe-3"
                                          />
                                        </div>
                                      </Col>
                                      <Col className="col-auto">
                                        <Button
                                          type="button"
                                          className="btn btn-rounded chat-send w-md"
                                          style={{ opacity: 0 }}
                                        >
                                          <span className="d-none d-sm-inline-block me-2">
                                            Send
                                          </span>{" "}
                                          <i className="mdi mdi-send" />
                                        </Button>
                                      </Col>
                                    </Row>
                                    <Row className="mt-3">
                                      <Col>
                                        <Label className="ps-2">Message:</Label>
                                        <div className="position-relative">
                                          <input
                                            type="text"
                                            value={newMessage.message ?? ""}
                                            onChange={e =>
                                              setNewMessage({
                                                ...newMessage,
                                                message: e.target.value,
                                              })
                                            }
                                            className="form-control chat-input"
                                            placeholder="Enter Message..."
                                          />
                                          <div className="chat-input-links">
                                            <ul className="list-inline mb-0">
                                              <li className="list-inline-item">
                                                <label
                                                  htmlFor="imageInput"
                                                  style={{
                                                    color: "#556ee6",
                                                    fontSize: 20,
                                                    marginTop: "8px",
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  <i
                                                    className="mdi mdi-file-document-outline"
                                                    id="Filetooltip"
                                                  />
                                                  <UncontrolledTooltip
                                                    placement="top"
                                                    target="Filetooltip"
                                                  >
                                                    Add File
                                                  </UncontrolledTooltip>
                                                </label>
                                                <input
                                                  type="file"
                                                  id="imageInput"
                                                  className="d-none"
                                                  onChange={handleFileChange}
                                                />
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </Col>
                                      <Col className="col-auto d-flex align-items-end">
                                        <Button
                                          type="button"
                                          color="primary"
                                          onClick={sendMessage}
                                          className="btn btn-rounded chat-send w-md "
                                        >
                                          <span className="d-none d-sm-inline-block me-2">
                                            Send
                                          </span>{" "}
                                          <i className="mdi mdi-send" />
                                        </Button>
                                      </Col>
                                    </Row>{" "}
                                  </div>
                                )}

                              {newMessage.file && (
                                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                  <div className="p-2">
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        <i className="bx bxs-file ms-4 font-size-20" />
                                      </Col>
                                      <Col>
                                        <a className="text-muted font-weight-bold">
                                          {newMessage.file.name}
                                        </a>
                                        <p className="mb-0">
                                          <strong>
                                            {newMessage.file.formattedSize}
                                          </strong>
                                        </p>
                                      </Col>
                                      <Col className="text-end">
                                        <button
                                          type="button"
                                          id="close_toggle"
                                          className="btn btn-sm btn-link ms-3 fs-18 text-danger"
                                          onClick={() =>
                                            setNewMessage({
                                              ...newMessage,
                                              file: undefined,
                                            })
                                          }
                                        >
                                          <i className="bx bx-x align-middle font-size-24"></i>
                                        </button>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              )}

                              {(currentChat.status == "accepted" ||
                                currentChat.isArchived) && (
                                <div className="p-3 chat-input-section">
                                  <Row>
                                    <Col>
                                      <div className="position-relative">
                                        <input
                                          type="text"
                                          value={newMessage.message ?? ""}
                                          onChange={e =>
                                            setNewMessage({
                                              ...newMessage,
                                              message: e.target.value,
                                            })
                                          }
                                          className="form-control chat-input"
                                          placeholder="Enter Message..."
                                        />
                                        <div className="chat-input-links">
                                          <ul className="list-inline mb-0">
                                            <li className="list-inline-item">
                                              <label
                                                htmlFor="imageInput"
                                                style={{
                                                  color: "#556ee6",
                                                  fontSize: 20,
                                                  marginTop: "8px",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                <i
                                                  className="mdi mdi-file-document-outline"
                                                  id="Filetooltip"
                                                />
                                                <UncontrolledTooltip
                                                  placement="top"
                                                  target="Filetooltip"
                                                >
                                                  Add File
                                                </UncontrolledTooltip>
                                              </label>
                                              <input
                                                type="file"
                                                id="imageInput"
                                                className="d-none"
                                                onChange={handleFileChange}
                                              />
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </Col>
                                    <Col className="col-auto d-flex align-items-end">
                                      <Button
                                        type="button"
                                        color="primary"
                                        onClick={sendMessage}
                                        className="btn btn-rounded chat-send w-md "
                                      >
                                        <span className="d-none d-sm-inline-block me-2">
                                          Send
                                        </span>{" "}
                                        <i className="mdi mdi-send" />
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              )}
                            </div>
                          </>
                        )}
                      </Card>
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Container>
        <DeclineModal
          show={declineModal}
          onCancel={_ => setDeclineModal(false)}
          onConfirm={handleDecline}
          message="Are you sure you want to decline this request? Your are not able to revert this operation. "
        />
      </div>
    </React.Fragment>
  )
}

export default Requests
