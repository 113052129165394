import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Collapse, Row } from "reactstrap"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "flatpickr/dist/themes/material_blue.css"
import { formatDate } from "helpers/misc_helpers"
import { Link } from "react-router-dom"
import PartModal from "./PartModal"
import DeleteModal from "components/Common/DeleteModal"
import { DeletePart } from "helpers/backend_helper"
import { useLoading } from "helpers/custom_hooks"
import PartFilter from "../../components/Common/PartFilters"
const { SearchBar } = Search

const PartList = ({ parts, loadParts }) => {
  const node = React.createRef()
  const [partModal, setPartModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [selectedPart, setSelectedPart] = useState(false)
  const [filteredParts, setFilteredParts] = useState(parts)
  const setLoading = useLoading()

  const pageOptions = {
    sizePerPage: 20,
    totalSize: parts.length,
    custom: true,
  }

  const columns = [
    {
      dataField: "internalPart",
      text: "IPN",
      sort: true,
    },
    {
      dataField: "partNumber",
      text: "Manufacturer Part no.",
      sort: true,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
    },
    {
      dataField: "manufacturer",
      text: "Manufacturer",
      sort: true,
    },
    {
      dataField: "quantity",
      text: "Stock",
      sort: true,
      formatter: cell => <>{cell} pc.</>,
      headerStyle: _ => ({
        textAlign: "right",
      }),
      align: () => "right",
    },
    {
      dataField: "price",
      text: "Unit Price",
      sort: true,
      formatter: (cell, row) => (
        <>{cell ? cell.toFixed(5) + " " + row.currency : "-"}</>
      ),
      headerStyle: _ => ({
        textAlign: "right",
      }),
      align: () => "right",
    },
    {
      dataField: "type",
      text: "Type",
      sort: true,
      headerStyle: _ => ({
        textAlign: "center",
      }),
      align: () => "center",
    },
    {
      dataField: "isHighrunner",
      text: "Highrunner",
      sort: true,
      formatter: cell => <>{cell == "1" ? "yes" : "no"}</>,
      headerStyle: _ => ({
        textAlign: "center",
      }),
      align: () => "center",
    },
    {
      dataField: "notes",
      text: "Notes",
      sort: true,
    },
    {
      dataField: "dateCreated",
      text: "Last Update",
      sort: true,
      formatter: cell => <>{formatDate(cell)}</>,
      headerStyle: _ => ({
        textAlign: "center",
      }),
      align: () => "center",
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "",
      align: () => "end",
      formatter: (_, part) => (
        <div className="d-flex justify-content-end gap-3">
          <Link className="text-success" to="#">
            <i
              className="mdi mdi-pencil font-size-18"
              id="edittooltip"
              onClick={() => {
                setSelectedPart(part)
                setPartModal(true)
              }}
            ></i>
          </Link>
          <Link className="text-danger" to="#">
            <i
              className="mdi mdi-delete font-size-18"
              id="deletetooltip"
              onClick={() => {
                setSelectedPart(part)
                setDeleteModal(true)
              }}
            ></i>
          </Link>
        </div>
      ),
    },
  ]

  const handleDelete = async () => {
    try {
      setLoading(true)

      await DeletePart(selectedPart.id)
      window.location.reload()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Card>
      <CardBody>
        <PaginationProvider
          pagination={paginationFactory(pageOptions || [])}
          keyField="id"
          columns={columns || []}
          data={filteredParts || []}
        >
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="id"
              data={filteredParts}
              columns={columns || []}
              remote={{ filter: true }}
              bootstrap4
              search
            >
              {toolkitProps => (
                <React.Fragment>
                  <Row>
                    <Col>
                      {" "}
                      <h4 className="mb-3">My Parts</h4>
                    </Col>
                  </Row>
                  <PartFilter
                    isOpen={true}
                    parts={parts}
                    setParts={setFilteredParts}
                    searchProps={toolkitProps.searchProps}
                  />
                  <div className="table-responsive">
                    <BootstrapTable
                      {...toolkitProps.baseProps}
                      {...paginationTableProps}
                      bordered={false}
                      hover={true}
                      classes={"table align-middle table-nowrap table-check"}
                      headerWrapperClasses={"table-light"}
                      ref={node}
                    />
                    {parts.length == 0 && (
                      <p className="text-center">
                        There are currently no parts!
                      </p>
                    )}
                  </div>
                  <div className="pagination pagination-rounded justify-content-end mb-2">
                    <PaginationListStandalone {...paginationProps} />
                  </div>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      </CardBody>
      {partModal && (
        <PartModal
          part={selectedPart}
          onSuccess={_ => {
            loadParts()
            setPartModal(false)
          }}
          onClose={_ => setPartModal(false)}
        />
      )}

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={_ => setDeleteModal(false)}
        displayMessage="Do you want to remove this part?"
      />
    </Card>
  )
}

export default PartList
