import React, { useEffect, useState } from "react"
import "./partsearch.css"
import { Card, CardBody, Col, Row } from "reactstrap"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "flatpickr/dist/themes/material_blue.css"
import { Link, useNavigate } from "react-router-dom"
import { IsPremiumUser, formatDate } from "helpers/misc_helpers"
import { useLoading, useShowLicenseModal } from "helpers/custom_hooks"
import { SavePart } from "helpers/backend_helper"
import ReactApexChart from "react-apexcharts"
import PartFilter from "components/Common/PartFilters"
import PremiumBadge from "components/Common/PremiumBadge"

const SearchResults = ({ parts, search }) => {
  const node = React.createRef()
  const setLoading = useLoading()
  const [filteredParts, setFiltererdParts] = useState(parts)
  const isSmallScreen = window.innerWidth < 1600
  const isPremium = IsPremiumUser()
  const navigate = useNavigate()
  const showLicenseModal = useShowLicenseModal()

  const options = {
    chart: { sparkline: { enabled: !0 } },
    dataLabels: { enabled: !1 },
    colors: ["#556ee6"],
    plotOptions: {
      radialBar: {
        hollow: { margin: 0, size: "60%" },
        track: { margin: 0 },
        dataLabels: { show: !1 },
      },
    },
  }

  const highlightText = text => {
    // Ersetzen Sie den regulären Ausdruck, um nur die erste Übereinstimmung zu finden
    const regex = new RegExp(escapeRegExp(search), "i")
    const highlighted = text.replace(
      regex,
      matched => `<span class="text-primary">${matched}</span>`
    )
    return { __html: highlighted }
  }
  const escapeRegExp = text => {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")
  }

  const columns = [
    {
      key: "status",
      dataField: "status",
      text: "Match",
      sort: false,
      headerStyle: _ => {
        return { width: "140px", textAlign: "center" }
      },
      hidden: isSmallScreen,
      formatter: (cell, row) => {
        let percentage = (search.length / row.partNumber.length) * 100

        return (
          <div className="d-flex justify-content-center align-items-center">
            <ReactApexChart
              options={options}
              series={[percentage]}
              type="radialBar"
              height={30}
              width={30}
              className="apex-charts"
            />
            <span className="ms-2">{percentage.toFixed(0)} %</span>
          </div>
        )
      },
    },
    {
      dataField: "partNumber",
      text: "Manufacturer Part no.",
      sort: true,
      formatter: cell => <p dangerouslySetInnerHTML={highlightText(cell)}></p>,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
    },
    {
      dataField: "manufacturer",
      text: "Manufacturer",
      sort: true,
    },
    {
      dataField: "quantity",
      text: "Stock",
      sort: true,
      formatter: cell => <>{cell} pc.</>,
      headerStyle: _ => ({
        textAlign: "right",
      }),
      align: () => "right",
    },
    {
      dataField: "price",
      text: "Unit Price",
      sort: true,
      formatter: (cell, row) => (
        <div className="blurred-text-container">
          <div className={isPremium ? "" : "blurred-text"}>
            {cell ? cell.toFixed(5) + " " + row.currency : "-"}
          </div>
          {!isPremium && (
            <div className="tooltip-text">This is a premium feature</div>
          )}
        </div>
      ),
      headerStyle: _ => ({
        textAlign: "right",
      }),
      align: () => "right",
    },
    {
      dataField: "name",
      text: "Member",
      sort: true,
      formatter: (cell, row) => (
        <div className="blurred-text-container">
          <div
            className={
              "d-flex align-items-center " + (isPremium ? "" : "blurred-text")
            }
          >
            {cell}
            <PremiumBadge accountType={row.accountType} />
          </div>
          {!isPremium && (
            <div className="tooltip-text">This is a premium feature</div>
          )}
        </div>
      ),
    },
    {
      dataField: "region",
      text: "Region",
      sort: true,
      formatter: (cell, row) => (
        <div className="blurred-text-container">
          <div className={isPremium ? "" : "blurred-text"}>{cell}</div>
          {!isPremium && (
            <div className="tooltip-text">This is a premium feature</div>
          )}
        </div>
      ),
    },
    {
      dataField: "type",
      text: "Type",
      sort: true,
      headerStyle: _ => ({
        textAlign: "center",
      }),
      align: () => "center",
      formatter: (cell, row) => (
        <div className="blurred-text-container">
          <div className={isPremium ? "" : "blurred-text"}>{cell}</div>
          {!isPremium && (
            <div className="tooltip-text">This is a premium feature</div>
          )}
        </div>
      ),
    },
    {
      dataField: "id",
      text: "",
      headerStyle: (colum, colIndex) => {
        return { width: "220px" }
      },
      formatter: (cellContent, row) => (
        <div className="d-flex">
          <button
            onClick={_ => {
              if (!isPremium) return showLicenseModal(true)
              navigate(`/part-search/${row.id}`)
            }}
            className="btn btn-sm btn-primary d-block"
          >
            View Details
          </button>
          <button
            className="btn btn-sm btn-dark ms-2"
            onClick={_ => {
              if (!isPremium) return showLicenseModal(true)
              handleSavePartClick(row)
            }}
          >
            Save for later
          </button>
        </div>
      ),
    },
  ]

  const pageOptions = {
    sizePerPage: 20,
    totalSize: filteredParts.length,
    custom: true,
  }

  const selectRow = {
    mode: "radio",
    hideSelectColumn: true,
    clickToSelect: true,
  }

  const handleSavePartClick = async part => {
    setLoading(true)

    await SavePart(part.id)

    setLoading(false)
  }

  return (
    <Card>
      <CardBody>
        <PaginationProvider
          pagination={paginationFactory(pageOptions || [])}
          keyField="id"
          columns={columns || []}
          data={filteredParts || []}
        >
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="id"
              data={filteredParts}
              columns={columns || []}
              remote={{ filter: true }}
              bootstrap4
              search
            >
              {toolkitProps => (
                <React.Fragment>
                  <Row>
                    <Col>
                      {" "}
                      <h4 className="mb-3">Results</h4>
                    </Col>
                  </Row>
                  <PartFilter
                    isOpen={true}
                    parts={parts}
                    setParts={setFiltererdParts}
                    searchProps={toolkitProps.searchProps}
                  />
                  <div className="table-responsive">
                    <BootstrapTable
                      {...toolkitProps.baseProps}
                      {...paginationTableProps}
                      bordered={false}
                      hover={true}
                      selectRow={selectRow}
                      classes={"table align-middle table-nowrap table-check"}
                      headerWrapperClasses={"table-light"}
                      ref={node}
                    />
                  </div>
                  <div className="pagination pagination-rounded justify-content-end mb-2">
                    <PaginationListStandalone {...paginationProps} />
                  </div>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      </CardBody>
    </Card>
  )
}

export default SearchResults
