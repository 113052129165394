import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"

//Import Images
import defaultAvatar from "../../assets/images/default-avatar.jpeg"
import { Link } from "react-router-dom"
import { formatDate } from "helpers/misc_helpers"
import { Rating } from "react-simple-star-rating"

function CardUser({ user, parts, rating }) {
  const [maxDate, setMaxDate] = useState()

  useEffect(() => {
    const maxDateObject = parts?.reduce((max, currentObject) => {
      const current = new Date(currentObject.dateCreated)

      return current > max ? current : max
    }, 0)
    setMaxDate(maxDateObject)
  }, [parts])

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Row>
            <Col lg="4">
              <div className="d-flex">
                <div className="me-3">
                  <div className="mb-2 mt-2 ">
                    <img
                      src={user?.company?.logoURL ?? defaultAvatar}
                      alt=""
                      style={{
                        maxWidth: "200px",
                        maxHeight: "100px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </div>
                <div className="flex-grow-1 align-self-center">
                  <div className="text-muted">
                    <p className="mb-2">Welcome to chipsconnect!</p>
                    <h5 className="mb-1">
                      {user?.firstname} {user?.lastname}
                    </h5>
                    <p className="mb-0">{user?.company?.name}</p>
                  </div>
                  {
                    <div style={{ width: "fit-content" }}>
                      <Rating
                        readonly
                        allowFraction
                        initialValue={rating?.avgRating ?? 0}
                        fillClassName="text-primary"
                        size="18"
                      />
                      <small className="text-muted text-center mb-0">
                        {"  "}
                        {rating?.numberOfRatings ?? 0} rating(s)
                      </small>
                    </div>
                  }
                </div>
              </div>
            </Col>

            <Col className="align-self-center">
              <div className="text-lg-center mt-4 mt-lg-0">
                <Row>
                  <Col>
                    <div>
                      <p className="text-muted text-truncate mb-2">
                        Total Parts
                      </p>
                      <h5 className="mb-0">{parts?.length} pc.</h5>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <p className="text-muted text-truncate mb-2">Stock</p>
                      <h5 className="mb-0">
                        {parts?.reduce((accumulator, currentValue) => {
                          return accumulator + currentValue.quantity
                        }, 0)}{" "}
                        pc.
                      </h5>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <p className="text-muted text-truncate mb-2">
                        Last Update
                      </p>
                      <h5 className="mb-0">
                        {maxDate ? formatDate(maxDate) : "-"}
                      </h5>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col lg="2" className="d-none d-lg-block text-right">
              <Link to="/settings" className="btn btn-primary float-end">
                <i className="bx bxs-cog align-middle me-1" /> Setting
              </Link>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default CardUser
