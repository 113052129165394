import { Field } from "formik"
import React from "react"
import { Input, InputGroup, Label } from "reactstrap"

const FormikPriceInputField = ({
  fieldName,
  label,
  fieldType = "text",
  touched,
  errors,
  disabled = false,
  className,
}) => {
  return (
    <div className={"mb-3 " + className}>
      <Label className="form-label">{label}</Label>
      <div className="position-relative">
        <InputGroup>
          <Field
            name={fieldName}
            type={fieldType}
            as={CustomInput}
            invalid={errors[fieldName] && touched[fieldName]}
            disabled={disabled}
          />
          <Field
            as="select"
            style={{ maxWidth: "70px" }}
            className="form-select"
            name="currency"
          >
            <option value="EUR">EUR</option>
            <option value="USD">USD</option>
            <option value="GBP">GBP</option>
            <option value="YEN">YEN</option>
          </Field>
        </InputGroup>
      </div>
      {errors[fieldName] && touched[fieldName] && (
        <div className="text-danger">{errors[fieldName]}</div>
      )}
    </div>
  )
}

const CustomInput = props => <Input className="form-control" {...props} />

export default FormikPriceInputField
