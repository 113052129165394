import React, { useState } from "react"
import { Card, CardBody, Col } from "reactstrap"
import { formatDate } from "helpers/misc_helpers"
import logo from "../../assets/images/logo-dark.png"
import { useNavigate } from "react-router-dom"
import CompanyInfoModal from "components/Common/CompanyInfoModal"

const Overview = ({ part }) => {
  const [companyInfoModal, setCompanyInfoModal] = useState(false)
  let navigate = useNavigate()

  return (
    <React.Fragment>
      <Col xl={5}>
        <Card className="h-100">
          <CardBody className="pb-0">
            <div className="text-center">
              <img
                src={part?.company?.logoURL ?? logo}
                alt=""
                height="50"
                className="mx-auto d-block w-auto"
                style={{
                  height: "10%",
                  width: "auto",
                  maxWidth: "250px",
                  maxHeight: "10vh",
                }}
              />
            </div>
            <div className="table-responsive mt-4">
              <table className="table">
                <tbody>
                  <tr>
                    <th scope="col">Part Number</th>
                    <td scope="col">{part?.partNumber}</td>
                  </tr>
                  <tr>
                    <th scope="row">Manufacturer:</th>
                    <td>{part?.manufacturer}</td>
                  </tr>
                  <tr>
                    <th scope="row">Stock</th>
                    <td>{part?.quantity} pc.</td>
                  </tr>
                  <tr>
                    <th scope="row">Price</th>
                    <td>
                      {part?.price
                        ? part.price.toFixed(5) + " " + part.currency
                        : "-"}{" "}
                    </td>
                  </tr>
                  <tr>
                    <th scope="col">Type</th>
                    <td scope="col">{part?.type}</td>
                  </tr>
                  <tr>
                    <th scope="row">Highrunner</th>
                    <td>{part?.isHighrunner == "1" ? "yes" : "no"}</td>
                  </tr>
                  <tr>
                    <th scope="row">Region:</th>
                    <td>{part?.company?.region}</td>
                  </tr>
                  <tr>
                    <th scope="col">Company</th>
                    <td scope="col">
                      {part?.company?.name}{" "}
                      <i
                        className="bx bx-info-circle ms-2 font-size-18"
                        style={{ cursor: "pointer" }}
                        onClick={_ => setCompanyInfoModal(true)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="col">IPN</th>
                    <td scope="col">{part?.internalPart}</td>
                  </tr>
                  <tr>
                    <th scope="col">Location</th>
                    <td scope="col">
                      {part?.company?.postalCode + " " + part?.company?.city}
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">Notes:</th>
                    <td>{part?.notes ? part.notes : "-"}</td>
                  </tr>
                  <tr>
                    <th scope="row">Last Updated</th>
                    <td>{formatDate(part?.dateCreated)}</td>
                  </tr>
                </tbody>
              </table>
              <div className="hstack gap-2">
                <button
                  onClick={() => navigate(-1)}
                  className="btn btn-soft-dark px-4 mx-auto d-flex align-items-center"
                >
                  <i className="bx bx-left-arrow-alt font-size-18 me-1" /> back
                </button>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <CompanyInfoModal
        part={part}
        modal={companyInfoModal}
        close={_ => setCompanyInfoModal(false)}
      />
    </React.Fragment>
  )
}

export default Overview
