import React, { useState } from "react"
import { Badge, Card, CardBody, Col, Row } from "reactstrap"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "flatpickr/dist/themes/material_blue.css"
import { formatDate } from "helpers/misc_helpers"
import { Link } from "react-router-dom"
import PartModal from "./PartModal"
import DeleteModal from "components/Common/DeleteModal"
import { DeleteWatchlistPart } from "helpers/backend_helper"
import { useLoading } from "helpers/custom_hooks"
const { SearchBar } = Search

const QCStatus = ({ status }) => {
  switch (status) {
    case "open":
      return <Badge className="bg-warning p-1"> {status} </Badge>

    case "in transit":
      return <Badge className="bg-secondary"> {status} </Badge>

    case "in review":
      return <Badge className="bg-danger"> {status} </Badge>

    case "report available":
      return <Badge className="bg-info"> {status} </Badge>

    default:
      return <Badge className="bg-success"> {status} </Badge>
  }
}

const QCOrders = ({ loadParts }) => {
  const node = React.createRef()
  const setLoading = useLoading()
  const [partModal, setPartModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [selectedPart, setSelectedPart] = useState(false)
  const [orders, setParts] = useState([
    {
      id: 1000,
      partNumber: "Test12345",
      description: "Das ist eine Beschreibung",
      manufacturer: "Infineon",
      status: "open",
      quantity: 1000,
      dateCreated: "05.01.2024",
    },
    {
      id: 1001,
      partNumber: "Test12345",
      description: "Das ist eine Beschreibung",
      manufacturer: "Infineon",
      status: "in transit",
      quantity: 1000,
      dateCreated: "05.23.2024",
    },
    {
      id: 1002,
      partNumber: "Test12345",
      description: "Das ist eine Beschreibung",
      manufacturer: "Infineon",
      status: "in review",
      quantity: 1000,
      dateCreated: "05.23.2024",
    },
    {
      id: 1003,
      partNumber: "Test12345",
      description: "Das ist eine Beschreibung",
      manufacturer: "Infineon",
      status: "report available",
      quantity: 1000,
      dateCreated: "05.23.2024",
    },
  ])

  const handleDelete = async () => {
    try {
      setLoading(true)

      await DeleteWatchlistPart(selectedPart.id)
      window.location.reload()
    } catch (error) {
      console.log(error)
    }

    setLoading(false)
  }

  const columns = [
    {
      dataField: "id",
      text: "Order ID",
      sort: true,
    },
    {
      dataField: "partNumber",
      text: "Part no.",
      sort: true,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
    },
    {
      dataField: "manufacturer",
      text: "Manufacturer",
      sort: true,
    },
    {
      dataField: "quantity",
      text: "Stock",
      sort: true,
      formatter: cell => <>{cell} pc.</>,
      headerStyle: _ => ({
        textAlign: "right",
      }),
      align: () => "right",
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: cell => <QCStatus status={cell} />,
      headerStyle: _ => ({
        textAlign: "center",
      }),
      align: () => "center",
    },
    {
      dataField: "dateCreated",
      text: "Last Update",
      sort: true,
      formatter: cell => <>{formatDate(cell)}</>,
      headerStyle: _ => ({
        textAlign: "center",
      }),
      align: () => "center",
    },
    {
      dataField: "menu",
      text: "",
      align: () => "end",
      formatter: (cellContent, row) => (
        <>
          <Link to={`/qc-details`} className="btn btn-primary ">
            Open
          </Link>
        </>
      ),
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: orders?.length,
    custom: true,
  }

  return (
    <Card>
      <CardBody>
        <PaginationProvider
          pagination={paginationFactory(pageOptions || [])}
          keyField="id"
          columns={columns || []}
          data={orders || []}
        >
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="id"
              data={orders || []}
              columns={columns || []}
              remote={{ filter: true }}
              bootstrap4
              search
            >
              {toolkitProps => (
                <React.Fragment>
                  <Row>
                    <Col></Col>
                    <Col className="d-flex justify-content-end">
                      <Link
                        to="/qc-wizard"
                        className="btn btn-primary mb-2 ms-4"
                      >
                        Create QC-Order
                      </Link>
                    </Col>
                  </Row>
                  <div className="table-responsive">
                    <BootstrapTable
                      {...toolkitProps.baseProps}
                      {...paginationTableProps}
                      bordered={false}
                      hover={true}
                      classes={"table align-middle table-nowrap table-check"}
                      headerWrapperClasses={"table-light"}
                      ref={node}
                    />
                    {orders?.length == 0 && (
                      <p className="text-center">
                        There are currently no orders!
                      </p>
                    )}
                  </div>
                  <div className="pagination pagination-rounded justify-content-end mb-2">
                    <PaginationListStandalone {...paginationProps} />
                  </div>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      </CardBody>

      {partModal && (
        <PartModal
          part={selectedPart}
          onClose={_ => setPartModal(false)}
          onSuccess={_ => {
            loadParts()
            setPartModal(false)
          }}
        />
      )}

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={_ => setDeleteModal(false)}
        displayMessage="Do you want to remove this part?"
      />
    </Card>
  )
}

export default QCOrders
