import React, { useEffect, useRef, useState } from "react"
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  Row,
  NavLink,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import { useLoading } from "helpers/custom_hooks"
import CompanySettingsTab from "./CompanySettingsTab"
import classnames from "classnames"
import { GetMyUser, UpdateLogo, UploadLogo } from "helpers/backend_helper"
import PersonalSettingsTab from "./PersonalSettingsTab"
import SecuritySettingsTab from "./SecuritySettingsTab"
import DeleteAccountTab from "./DeleteAccountTab"
import defaultAvatar from "../../assets/images/default-avatar.jpeg"
import LocationsSettingsTab from "./LocationsSettingsTab"
import { GetLoggedOnUser, displayMessage } from "helpers/misc_helpers"
import PricingSettingsTab from "./PricingSettingsTab"
import { useParams } from "react-router-dom"

const Settings = props => {
  const [error, setError] = useState()
  const [success, setSuccess] = useState()
  const [activeTab, setActiveTab] = useState(0)
  const [user, setUser] = useState()
  const fileInputRef = useRef()
  const setLoading = useLoading()
  const loggedOnUser = GetLoggedOnUser()

  const [navLinks, setNavLinks] = useState([
    { label: "My Profile", icon: "bx-user" },
    { label: "Security", icon: "bx-lock" },
    { label: "Pricing", icon: "bx-money" },
  ])

  useEffect(() => {
    if (loggedOnUser.userType == "Admin")
      setNavLinks([
        ...navLinks,
        { label: "Company Details", icon: "bx-buildings" },
        { label: "Locations Details", icon: "bx-buildings" },
        { label: "Delete Account", icon: "bx-trash" },
      ])
    loadData()
    setInitialTab()
  }, [])

  const loadData = async () => {
    setLoading(true)
    const result = await GetMyUser()
    setUser(result)
    setLoading(false)
  }

  const setInitialTab = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const tab = searchParams.get("tab")

    if (tab) {
      const parsedTab = parseInt(tab, 10) // Parse tab to integer with radix 10
      if (!isNaN(parsedTab) && parsedTab < navLinks.length)
        setActiveTab(parsedTab)
    }
  }

  const handleFileChange = async event => {
    const selectedFile = event.target.files[0]

    if (!checkFile(selectedFile)) return

    if (selectedFile) {
      setLoading(true)

      const formData = new FormData()
      formData.append("file", selectedFile)

      try {
        const result = await UploadLogo(formData)

        await UpdateLogo({ logoURL: result.url })

        window.location.reload()
      } catch (error) {
        console.error("Error uploading image: ", error)
        setError("An unexpected error happened!")
      }
    }
  }

  const checkFile = file => {
    if (file.size > 2 * 1024 * 1024) {
      displayMessage("File size exceeds maximum size of 2MB!", setError)
      return false
    }

    if (!file.type.startsWith("image/")) {
      displayMessage("Invalid file format!", setError)
      return false
    }
    return true
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Settings")}
            breadcrumbItem={props.t("Settings")}
          />
          <Row>
            <Col lg="2">
              <Card className="email-leftbar mb-0 w-100">
                <div className="mb-2 mt-2 ms-auto me-auto position-relative d-flex justify-content-center">
                  <img
                    src={user?.company?.logoURL ?? defaultAvatar}
                    alt=""
                    style={{
                      maxWidth: "90%",
                      maxHeight: "150px",
                      objectFit: "cover",
                    }}
                  />
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      top: 0,
                      left: 0,
                    }}
                    className="img-overlay bg-light position-absolute d-flex justify-content-center align-items-center"
                    onClick={_ => fileInputRef.current.click()}
                  >
                    <i className="bx bx-edit font-size-24" />
                  </div>
                </div>
                <div className="mail-list mt-4">
                  <Nav tabs className="nav-tabs-custom" vertical role="tablist">
                    {navLinks.map((navlink, index) => (
                      <NavItem key={index} className="mt-1">
                        <NavLink
                          className={classnames({
                            active: activeTab === index,
                          })}
                          onClick={() => {
                            setActiveTab(index)
                          }}
                        >
                          <i className={"me-2 bx " + navlink.icon}></i>
                          {navlink.label}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                </div>
              </Card>
            </Col>
            <Col lg="10">
              <Card className="h-100">
                <CardBody>
                  {error && (
                    <Alert color="danger" style={{ marginTop: "13px" }}>
                      {error}
                    </Alert>
                  )}
                  {success && (
                    <Alert color="success" style={{ marginTop: "13px" }}>
                      {success}
                    </Alert>
                  )}

                  {activeTab === 0 && user && (
                    <PersonalSettingsTab
                      setError={setError}
                      setSuccess={setSuccess}
                      setLoading={setLoading}
                      user={user}
                    />
                  )}

                  {activeTab === 1 && user && (
                    <SecuritySettingsTab
                      setError={setError}
                      setSuccess={setSuccess}
                      setLoading={setLoading}
                      user={user}
                    />
                  )}

                  {activeTab === 2 && user && (
                    <PricingSettingsTab user={user} />
                  )}

                  {activeTab === 3 && user && (
                    <CompanySettingsTab
                      setError={setError}
                      setSuccess={setSuccess}
                      setLoading={setLoading}
                      user={user}
                    />
                  )}

                  {activeTab === 4 && user && (
                    <LocationsSettingsTab
                      setError={setError}
                      setSuccess={setSuccess}
                      setLoading={setLoading}
                      user={user}
                    />
                  )}

                  {activeTab === 5 && user && (
                    <DeleteAccountTab
                      setError={setError}
                      setSuccess={setSuccess}
                      setLoading={setLoading}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Settings.propTypes = {}

export default withTranslation()(Settings)
