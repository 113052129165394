import { useLoading } from "helpers/custom_hooks"
import React, { useState } from "react"

import { Alert, Col, Row, TabPane } from "reactstrap"
//Import Pricing Cards
import CardPricing from "../../../components/Common/card-pricing"
import { UpdateBilling } from "helpers/backend_helper"
import { displayMessage } from "helpers/misc_helpers"

const PricingTab = ({ nextTab, company }) => {
  const setLoading = useLoading()
  const [error, setError] = useState()
  const [anual, setAnual] = useState(true)

  const handleSave = async accountType => {
    try {
      setLoading(true)

      const reqObj = {
        accountType: accountType,
        billingPeriod: anual ? "anual" : "half-yearly",
      }

      await UpdateBilling(reqObj)

      nextTab()
    } catch (error) {
      if (error?.response?.status === 400)
        displayMessage(error.response.data, setError)
      else displayMessage("An unexpected error happened!", setError)
      console.error(error)
    }
    setLoading(false)
  }

  const pricings = [
    {
      id: 1,
      title: "Free Package",
      description: " ",
      description2: "Access to our part search and watchlist.",
      price: anual ? "0" : "0",
      duration: anual ? "per year" : "per half year",
      link: _ => handleSave("Standard"),
      btnText: "Choose Plan",
      userType: "Free",
      features: [
        { title: "Limited part search", icon: "bx bx-check", color: "primary" },
        {
          title: "Upload your Excess material list and Watchlist",
          icon: "bx bx-check",
          color: "primary",
        },
        { title: "Single User Account", icon: "bx bx-check", color: "primary" },
        { title: "Buy / Sell parts", icon: "bx bx-x", color: "danger" },
      ],
    },
    {
      id: 2,
      title: "Basic Package",
      description: "Pricing plan for basic features",
      description2:
        "Unlimited access to all basic features and no user restrictions",
      price: anual ? "500" : "250",
      duration: anual ? "per year" : "per half year",
      link: _ => handleSave("Premium"),
      btnText: "Choose Plan",
      userType: "Standard",
      features: [
        {
          title: "Unlimited part search",
          icon: "bx bx-check",
          color: "primary",
        },
        { title: "Buy / Sell parts", icon: "bx bx-check", color: "primary" },
        { title: "Unlimited user", icon: "bx bx-check", color: "primary" },
        { title: "API interface", icon: "bx bx-x", color: "danger" },
      ],
    },
    {
      id: 3,
      title: "Add-on API Package",
      description: "Pricing plan to get the API",
      description2:
        "No one-time costs for API implementation. (Basic Package required)",
      icon: "",
      price: anual ? "300" : "150",
      duration: anual ? "per year" : "per half year",

      btnText: "Choose Plan",
      features: [
        { title: "API interface", icon: "bx bx-check", color: "primary" },
        { title: "API maintenance", icon: "bx bx-check", color: "primary" },
        { title: "Automatic updates", icon: "bx bx-check", color: "primary" },
        { title: "Basic package", icon: "bx bx-x", color: "danger" },
      ],
    },
  ]

  return (
    <TabPane tabId={5}>
      {error && (
        <Alert color="danger" style={{ marginTop: "13px" }}>
          {error}
        </Alert>
      )}
      <div>
        <Row className="justify-content-center mt-5">
          <Col lg={6}>
            <div className="text-center mb-3">
              <h4>Choose your Pricing package</h4>
              <p className="text-muted">Please select</p>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className="text-center mb-3">
            <div className="btn-group" role="group">
              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="btnradio4"
                autoComplete="off"
                defaultChecked
                onChange={e => setAnual(true)}
              />
              <label className="btn btn-outline-secondary" htmlFor="btnradio4">
                Anually
              </label>

              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="btnradio5"
                autoComplete="off"
                onChange={e => setAnual(false)}
              />
              <label className="btn btn-outline-secondary" htmlFor="btnradio5">
                Semi-Anually
              </label>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          {pricings.map((pricing, key) => (
            <CardPricing pricing={pricing} key={"_pricing_" + key} />
          ))}
        </Row>
      </div>
    </TabPane>
  )
}

export default PricingTab
