import React, { useEffect, useState } from "react"
import { Container, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import Overview from "./Overview"
import RequestSection from "./RequestSection"
import { useLoading } from "helpers/custom_hooks"
import { useNavigate, useParams } from "react-router-dom"
import { GetPartByID } from "helpers/backend_helper"

const PartSearchDetails = () => {
  const [part, setPart] = useState()
  const navigate = useNavigate()
  const setLoading = useLoading()
  let { partID } = useParams()

  const loadData = async () => {
    setLoading(true)
    try {
      const part = await GetPartByID(partID)
      setPart(part)
    } catch (error) {
      console.log(error)
      if (error.response.status === 404) navigate("/404")
      else alert("An unexpected error happened!")
    }
    setLoading(false)
  }

  useEffect(() => {
    loadData()
  }, [partID])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Part Search" breadcrumbItem="Details" />

          <Row>
            <Overview part={part} />
            {part && <RequestSection part={part} />}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PartSearchDetails
