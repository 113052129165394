import React, { useState } from "react"
import {
  Row,
  Col,
  Card,
  Form,
  CardBody,
  CardSubtitle,
  Container,
  Table,
  Alert,
  Tooltip,
} from "reactstrap"
import Dropzone from "react-dropzone"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import { UploadPartList } from "helpers/backend_helper"
import { useLoading } from "helpers/custom_hooks"
import { UserInfoError, displayMessage } from "helpers/misc_helpers"
import _ from "lodash"
import * as XLSX from "xlsx"
import MessageModal from "components/Common/MessageModal"
import UploadFile from "./Part-Upload.xlsx"

const PartsUpload = () => {
  const setLoading = useLoading()
  const navigate = useNavigate()
  const [error, setError] = useState()
  const [selectedFiles, setselectedFiles] = useState([])
  const [data, setData] = useState([])
  const [successModal, setSuccessModal] = useState(false)
  const [showParts, setShowParts] = useState(false)

  const handleFileUpload = files => {
    const file = files[0]
    Object.assign(file, {
      preview: URL.createObjectURL(file),
      formattedSize: formatBytes(file.size),
    })

    const reader = new FileReader()

    reader.onload = e => {
      const fileData = e.target.result
      parseExcel(fileData)
    }

    reader.readAsArrayBuffer(file)
    setselectedFiles(files)
  }

  const parseExcel = fileData => {
    try {
      const workbook = XLSX.read(fileData, { type: "array" })
      const sheetName = workbook.SheetNames[0]
      const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName])

      const data = MapToPartList(sheetData)
      setData(data)
    } catch (error) {
      if (error instanceof UserInfoError)
        return displayMessage(error.message, setError)
      else displayMessage("An unexpected error happened!", setError)
    }
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const MapToPartList = csvData => {
    const result = []

    csvData.forEach((e, index) => {
      var price = getFloat(e["target price (optional)"])
      var quantity = getFloat(e["quantity"])

      if (!quantity)
        throw new UserInfoError(
          "Quantity in row " + (index + 1) + " is not a valid number!"
        )

      if (e["target price (optional)"] && !price)
        throw new UserInfoError(
          "Target price in row " + (index + 1) + " is not a valid number!"
        )
      var newRow = {
        internalPart: e["internal part number (optional)"]
          ? "" + e["internal part number (optional)"]
          : "",
        partNumber: e["manufacturer part number"]
          ? e["manufacturer part number"] + ""
          : "",
        manufacturer: e["manufacturer"],
        price,
        currency: e["currency"] ? "" + e["currency"] : "EUR",
        quantity: quantity,
        description: e["description (optional)"]
          ? e["description (optional)"] + ""
          : "",
        notes: e["notes (optional)"] ? e["notes (optional)"] + "" : "",
        isHighrunner:
          "" + e["isHighrunner (0 = no & 1 = yes | optional)"] ?? "0",
        type:
          "" + e["is excess (0 = yes & 1 = APQ | optional)"] == "1"
            ? "APQ"
            : "Excess",
      }

      if (!newRow.manufacturer)
        throw new UserInfoError(
          "Manufacturer in row " + (index + 1) + " is not defined!"
        )

      if (!newRow.partNumber)
        throw new UserInfoError(
          "Manufacturer in row " + (index + 1) + " is not defined!"
        )

      result.push(_.omitBy(newRow, _.isNil))
    })

    return result
  }

  const getFloat = s => {
    try {
      var number = parseFloat(s)
      if (isNaN(number)) return null
      return number
    } catch (error) {
      return null
    }
  }

  const handleSave = async () => {
    setLoading(true)
    try {
      const extend = document.getElementById("partOptionExtend").checked

      await UploadPartList({
        parts: data,
        uploadType: extend ? "extend" : "replace",
      })

      setSuccessModal(true)
    } catch (error) {
      error?.response?.status == 400
        ? displayMessage(error.response.data, setError)
        : displayMessage("An unexpected error happened!", setError)
      console.log(error)
    }

    setLoading(false)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Dashboard" breadcrumbItem="Parts Upload" />

          {error && <Alert color="danger">{error}</Alert>}
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col lg="6">
                      <h6 className="card-title">Upload</h6>
                      <CardSubtitle className="mb-3">
                        {" "}
                        Please download our template and fill it up with your
                        data. Afterwards you can upload it here and we will
                        process your data. <br />
                        For additional guidance, we invite you to watch our
                        instructional video{" "}
                        <a
                          href="https://chipsconnect.com/instruction-videos/Parts-Upload.mp4"
                          target="_blank"
                          rel="noreferrer"
                        >
                          here.
                        </a>
                      </CardSubtitle>
                    </Col>
                    <Col className="d-flex align-items-center justify-content-end">
                      <a
                        href={UploadFile}
                        className="btn btn-primary"
                        target="_blank"
                        rel="noreferrer"
                        download="parts-upload.xlsx"
                      >
                        Download Template
                      </a>
                    </Col>
                  </Row>

                  <Form>
                    <Dropzone
                      onDrop={acceptedFiles => {
                        handleFileUpload(acceptedFiles)
                      }}
                      accept={{
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                          [],
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick mt-2"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                            </div>
                            <h4>Drop files here or click to upload.</h4>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedFiles.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <i className="mdi mdi-file-delimited font-size-24 font-primary text-success" />
                                </Col>
                                <Col className="col-auto me-4 ">
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        )
                      })}
                    </div>
                  </Form>
                </CardBody>
                <CardBody>
                  {data && data.length > 0 && (
                    <>
                      <div className="mb-4">
                        <div>
                          <label>Type Of Upload: </label>

                          <div className="form-check mb-3 w-50">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="partOption"
                              id="partOptionExtend"
                              defaultChecked
                            />
                            <label
                              className="form-check-label"
                              htmlFor="partOptionExtend"
                            >
                              extend existing parts{" "}
                              <span className="text-muted text-small">
                                (Extend previously uploaded parts list on your
                                account)
                              </span>
                            </label>
                          </div>
                          <div className="form-check mb-3 w-50">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="partOption"
                              id="partOptionReplace"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="partOptionReplace"
                            >
                              replace existing parts{" "}
                              <span className="text-muted text-small">
                                (Delete previously uploaded parts list on your
                                account)
                              </span>
                            </label>
                          </div>
                        </div>
                        <div className="d-flex">
                          <button
                            type="button"
                            onClick={_ => setShowParts(!showParts)}
                            className="btn btn-secondary me-2"
                          >
                            {showParts ? "Hide" : "Show"} Parts
                          </button>
                          <button
                            type="button"
                            onClick={handleSave}
                            className="btn btn-primary ms-2"
                          >
                            Upload Parts
                          </button>
                        </div>
                      </div>
                      {showParts && (
                        <Table>
                          <thead>
                            <tr>
                              <th>internal part number</th>
                              <th>part number</th>
                              <th>manufacturer</th>
                              <th>target price</th>
                              <th>quantity</th>
                              <th>description</th>
                              <th>notes</th>
                              <th className="text-center">highrunner</th>
                              <th className="text-center">type</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((element, index) => (
                              <tr key={index}>
                                <td>{element.internalPart ?? ""}</td>
                                <td>{element.partNumber}</td>
                                <td>{element.manufacturer ?? ""}</td>
                                <td>
                                  {element.price?.toFixed(5)}{" "}
                                  {element.price > 0 ? element.currency : ""}
                                </td>
                                <td>{element.quantity} pc.</td>
                                <td>{element.description}</td>
                                <td>{element.notes}</td>
                                <td className="text-center">
                                  {"" + element.isHighrunner == "1"
                                    ? "true"
                                    : "false"}
                                </td>
                                <td className="text-center">{element.type}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      )}
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <MessageModal
        show={successModal}
        displayMessage={"Upload successful!"}
        onCloseClick={_ => navigate("/dashboard")}
        color="success"
        icon="bx bx-check"
      />
    </React.Fragment>
  )
}

export default PartsUpload
