import React from "react"
import { Col, Row } from "reactstrap"
import { ResetPassword } from "helpers/backend_helper"
import { displayMessage } from "helpers/misc_helpers"

const SecuritySettingsTab = ({ user, setLoading, setError, setSuccess }) => {
  const handlePasswordReset = async _ => {
    try {
      setLoading(true)
      await ResetPassword({ email: user.email })
      displayMessage("Please check your email inbox!", setSuccess)
    } catch (error) {
      if (error?.response?.status === 400)
        displayMessage(error.response.data, setError)
      else displayMessage("An unexpected error happened!", setError)
      console.error(error)
    }
    setLoading(false)
  }

  return (
    <div>
      <h5 className="mb-4">Security</h5>
      <Row>
        <Col lg="8" className="my-4">
          <h6 className="mb-3">Change your password:</h6>
          <p>
            If you want to change your password, click the button below. <br />
            We will send you an email to reset your password.
          </p>
          <button className="btn btn-warning" onClick={handlePasswordReset}>
            Reset Password
          </button>
        </Col>
      </Row>
    </div>
  )
}

export default SecuritySettingsTab
