import React, { useState } from "react"
import { Modal, ModalBody } from "reactstrap"

const DeclineModal = ({ show, onCancel, onConfirm, message }) => {
  const [comment, setComment] = useState("")
  return (
    <Modal size="md" isOpen={show} toggle={onCancel} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <button
            type="button"
            onClick={onCancel}
            className="btn-close position-absolute end-0 top-0 m-3"
          ></button>

          <p className="text-muted font-size-16 mb-0">
            Are you sure to decline this request?
          </p>
          <p className="mb-4 text-danger">
            Your are not able to revert this operation.
          </p>
          <input
            placeholder="comment"
            className="form-control mb-4"
            value={comment}
            onChange={e => setComment(e.target.value)}
          />

          <div className="hstack gap-2 justify-content-center mb-0">
            <button
              type="button"
              className="btn btn-secondary w-100"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary w-100"
              onClick={_ => {
                onConfirm(comment)
              }}
            >
              Decline
            </button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default DeclineModal
