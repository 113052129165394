import React from "react"
import { Link } from "react-router-dom"

import { Card, CardBody } from "reactstrap"

//SimpleBar
import SimpleBar from "simplebar-react"

const Activity = props => {
  const file = { name: "Offer.pdf", size: "2.25 Mb" }
  const file2 = { name: "Report.pdf", size: "3.25 Mb" }

  return (
    <Card>
      <CardBody>
        <div className="d-flex align-items-start">
          <div className="me-2">
            <h5 className="card-title mb-4">Activity</h5>
          </div>
        </div>
        <SimpleBar className="mt-2">
          <ul className="verti-timeline list-unstyled">
            <li className="event-list">
              <div className="event-timeline-dot">
                <i className="bx bx-right-arrow-circle font-size-18"></i>
              </div>
              <div className="d-flex">
                <div className="flex-shrink-0 me-3">
                  <h5 className="font-size-14">
                    01.05.2024{" "}
                    <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
                  </h5>
                </div>
                <div className="flex-grow-1">
                  <div>Request Created</div>
                </div>
              </div>
            </li>
            <li className="event-list">
              <div className="event-timeline-dot">
                <i className="bx bx-right-arrow-circle font-size-18"></i>
              </div>
              <div className="d-flex">
                <div className="flex-shrink-0 me-3">
                  <h5 className="font-size-14">
                    03.05.2024{" "}
                    <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
                  </h5>
                </div>
                <div className="flex-grow-1">
                  <div>Offer provided by QC Labor</div>
                </div>
              </div>
              <div className="d-flex align-items-center mt-3">
                <div style={{ width: "45px" }}>
                  <div className="avatar-sm">
                    <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-24">
                      <i className="bx bxs-file-doc" />
                    </span>
                  </div>
                </div>
                <div className="w-25 ms-4">
                  <h5 className="font-size-14 mb-1">
                    <Link to="#" className="text-dark">
                      {file.name}
                    </Link>
                  </h5>
                  <small>Size : {file.size}</small>
                </div>
                <div>
                  <div className="text-center">
                    <Link to={file.link} className="text-dark">
                      <i className="bx bx-download h3 m-0" />
                    </Link>
                  </div>
                </div>
              </div>
            </li>
            <li className="event-list">
              <div className="event-timeline-dot">
                <i className="bx bx-right-arrow-circle font-size-18"></i>
              </div>
              <div className="d-flex">
                <div className="flex-shrink-0 me-3">
                  <h5 className="font-size-14">
                    03.05.2024{" "}
                    <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
                  </h5>
                </div>
                <div className="flex-grow-1">
                  <div>Offer accepted by both parties</div>
                </div>
              </div>
            </li>
            <li className="event-list">
              <div className="event-timeline-dot">
                <i className="bx bx-right-arrow-circle font-size-18"></i>
              </div>
              <div className="d-flex">
                <div className="flex-shrink-0 me-3">
                  <h5 className="font-size-14">
                    03.05.2024{" "}
                    <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
                  </h5>
                </div>
                <div className="flex-grow-1">
                  <div>In Transit</div>
                </div>
              </div>
              <div className="mt-2">
                <p className="mb-0" style={{ cursor: "pointer" }}>
                  <b>Trackingnumber:</b> 12345678910{" "}
                  <i className="bx bx-copy text-primary ms-1" />
                </p>
                <p className="mt-1">
                  <b>Delivery Service:</b> DHL
                </p>
              </div>
            </li>
            <li className="event-list">
              <div className="event-timeline-dot">
                <i className="bx bx-right-arrow-circle font-size-18"></i>
              </div>
              <div className="d-flex">
                <div className="flex-shrink-0 me-3">
                  <h5 className="font-size-14">
                    03.05.2024{" "}
                    <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
                  </h5>
                </div>
                <div className="flex-grow-1">
                  <div>In Review</div>
                </div>
              </div>
            </li>
            <li className="event-list">
              <div className="event-timeline-dot">
                <i className="bx bx-right-arrow-circle font-size-18"></i>
              </div>
              <div className="d-flex">
                <div className="flex-shrink-0 me-3">
                  <h5 className="font-size-14">
                    12.05.2024{" "}
                    <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
                  </h5>
                </div>
                <div className="flex-grow-1">
                  <div>QC report sent</div>
                </div>
              </div>
              <div className="d-flex align-items-center mt-3">
                <div style={{ width: "45px" }}>
                  <div className="avatar-sm">
                    <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-24">
                      <i className="bx bxs-file-doc" />
                    </span>
                  </div>
                </div>
                <div className="w-25 ms-4">
                  <h5 className="font-size-14 mb-1">
                    <Link to="#" className="text-dark">
                      {file2.name}
                    </Link>
                  </h5>
                  <small>Size : {file2.size}</small>
                </div>
                <div>
                  <div className="text-center">
                    <Link to={file2.link} className="text-dark">
                      <i className="bx bx-download h3 m-0" />
                    </Link>
                  </div>
                </div>
              </div>
            </li>
            <li className="event-list">
              <div className="event-timeline-dot">
                <i className="bx bx-right-arrow-circle font-size-18"></i>
              </div>
              <div className="d-flex">
                <div className="flex-shrink-0 me-3">
                  <h5 className="font-size-14">
                    14.05.2024{" "}
                    <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
                  </h5>
                </div>
                <div className="flex-grow-1">
                  <div>Report accepted</div>
                </div>
              </div>
            </li>
            <li className="event-list active">
              <div className="event-timeline-dot">
                <i className="bx bxs-right-arrow-circle font-size-18 bx-fade-right"></i>
              </div>
              <div className="d-flex">
                <div className="flex-shrink-0 me-3">
                  <h5 className="font-size-14">
                    20.05.2024{" "}
                    <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
                  </h5>
                </div>
                <div className="flex-grow-1">
                  <div>In Transit</div>
                </div>
              </div>
              <div className="mt-2">
                <p className="mb-0" style={{ cursor: "pointer" }}>
                  <b>Trackingnumber:</b> 12345678910{" "}
                  <i className="bx bx-copy text-primary ms-1" />
                </p>
                <p className="mt-1">
                  <b>Delivery Service:</b> DHL
                </p>
              </div>
            </li>
          </ul>
        </SimpleBar>
      </CardBody>
    </Card>
  )
}

export default Activity
