import React from "react"
import { Label, TabPane } from "reactstrap"

const QCLaborTab = ({ nextTab }) => {
  return (
    <TabPane tabId={2}>
      <h6 className="mb-4 card-title">QC-Labors:</h6>
      <p>Please select a QC Labor</p>
      <div className="controls mb-4">
        <Label>Position</Label>

        <div className="form-check mb-2">
          <input
            type="radio"
            id="radio5"
            name="positions"
            className="form-check-input"
            value="toast-top-right"
            defaultChecked
          />
          <Label className="form-check-label" htmlFor="radio5">
            QC Labor 1
          </Label>
        </div>

        <div className="form-check mb-2">
          <input
            type="radio"
            id="radio6"
            name="positions"
            className="form-check-input"
            value="toast-bottom-right"
          />
          <Label className="form-check-label" htmlFor="radio6">
            QC Labor 2
          </Label>
        </div>

        <div className="form-check mb-2">
          <input
            type="radio"
            id="radio7"
            name="positions"
            className="form-check-input"
            value="toast-bottom-left"
          />
          <Label className="form-check-label" htmlFor="radio7">
            QC Labor 3
          </Label>
        </div>
        <div className="d-flex justify-content-end">
          <button type="submit" onClick={nextTab} className="btn btn-primary">
            Next
          </button>
        </div>
      </div>
    </TabPane>
  )
}

export default QCLaborTab
