import React, { useState } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
  Button,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

import { Link } from "react-router-dom"

// import images
import profileImg from "../../assets/images/profile-img.png"
import logoImg from "../../assets/images/logo-sm.png"
import { useLoading } from "helpers/custom_hooks"
import { RegisterUser } from "helpers/backend_helper"
import { displayMessage } from "helpers/misc_helpers"
import withRouter from "components/Common/withRouter"

const Register = props => {
  const [error, setError] = useState()
  const [success, setSuccess] = useState()
  const [passwordVisible, setPasswordVisible] = useState(false)
  const setLoading = useLoading()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
      firstname: "",
      lastname: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      firstname: Yup.string().required("Please Enter Your Firstname"),
      lastname: Yup.string().required("Please Enter Your Lastname"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: async values => {
      try {
        setLoading(true)
        await RegisterUser(values)
        setSuccess(
          "Thank you for your registration! Please check your inbox and confirm your email!"
        )
      } catch (err) {
        console.log(err)
        err?.response?.status == 400
          ? displayMessage(err.response.data, setError)
          : displayMessage("An unhandled exception happend!", setError)
      }
      setLoading(false)
    },
  })

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Free Register</h5>
                        <p>Get your free account now.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {error && <Alert color="danger">{error}</Alert>}
                      {success && <Alert color="success">{success}</Alert>}

                      <div className="mb-3">
                        <Label className="form-label">Firstname</Label>
                        <Input
                          id="firstname"
                          name="firstname"
                          className="form-control"
                          placeholder="Enter firstname"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.firstname}
                          invalid={
                            validation.touched.firstname &&
                            validation.errors.firstname
                              ? true
                              : false
                          }
                        />
                        {validation.touched.firstname &&
                        validation.errors.firstname ? (
                          <FormFeedback type="invalid">
                            {validation.errors.firstname}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Lastname</Label>
                        <Input
                          id="lastname"
                          name="lastname"
                          className="form-control"
                          placeholder="Enter lastname"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.lastname}
                          invalid={
                            validation.touched.lastname &&
                            validation.errors.lastname
                              ? true
                              : false
                          }
                        />
                        {validation.touched.lastname &&
                        validation.errors.lastname ? (
                          <FormFeedback type="invalid">
                            {validation.errors.lastname}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <div className="input-group rounded bg-light">
                          <Input
                            name="password"
                            type={passwordVisible ? "text" : "password"}
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />

                          <Button
                            color="primary"
                            type="button"
                            id="button-addon2"
                            style={{ borderRadius: "0 0.25rem 0.25rem 0" }}
                            onClick={_ => setPasswordVisible(!passwordVisible)}
                          >
                            <i className="mdi mdi-eye"></i>
                          </Button>
                        </div>
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="form-check mt-3">
                        <Input
                          type="checkbox"
                          className="form-check-input"
                          id="legalnotice"
                          required
                        />
                        <label
                          htmlFor="legalnotice"
                          className="form-check-label"
                        >
                          I have read and accepted the{" "}
                          <a
                            href="https://chipsconnect.com/terms"
                            className="text-primary"
                            target={"_blank"}
                            rel="noreferrer"
                          >
                            Terms of Use.
                          </a>{" "}
                        </label>
                      </div>
                      <div className="form-check mt-3">
                        <Input
                          type="checkbox"
                          className="form-check-input"
                          id="dsgvonotice"
                          required
                        />
                        <label
                          htmlFor="dsgvonotice"
                          className="form-check-label"
                        >
                          I have read and accepted the{" "}
                          <a
                            href="https://chipsconnect.com/privacy_policy"
                            className="text-primary"
                            target={"_blank"}
                            rel="noreferrer"
                          >
                            Privacy Policy.
                          </a>{" "}
                        </label>
                      </div>
                      <div className="form-check mt-3">
                        <Input
                          type="checkbox"
                          className="form-check-input"
                          id="legalnotice2"
                          required
                        />
                        <label
                          htmlFor="legalnotice2"
                          className="form-check-label"
                        >
                          I hereby confirm that I am authorized to use the
                          chipsconnect platform on behalf of my company and to
                          represent my company in conducting all necessary
                          functions and transactions.
                        </label>
                      </div>

                      <div className="mt-4">
                        <button
                          className="btn btn-primary btn-block "
                          type="submit"
                        >
                          Register
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {" "}
                    Login
                  </Link>{" "}
                </p>
                <p>© {new Date().getFullYear()} chipsconnect</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Register)
