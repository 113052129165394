import { GetPartByID } from "helpers/backend_helper"
import React, { useEffect, useState } from "react"
import { Card, CardBody, Modal } from "reactstrap"

const PartInfoModal = ({ modal, close, request }) => {
  const [loading, setLoading] = useState()
  const [part, setPart] = useState()

  useEffect(() => {
    if (modal) loadData()
  }, [modal])

  const loadData = async _ => {
    try {
      setLoading(true)
      const result = await GetPartByID(request.partID)
      setPart(result)
      setLoading(false)
    } catch (error) {
      console.log(error)
      alert("An unexpected error happened!")
    }
  }

  return (
    <Modal isOpen={modal} toggle={close}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Part Info
        </h5>
        <button
          type="button"
          onClick={close}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Card>
          <CardBody>
            <div className="table-responsive mt-4">
              {!loading ? (
                <table className="table">
                  <tbody>
                    <tr>
                      <th scope="col">Part Number</th>
                      <td scope="col">{part?.partNumber}</td>
                    </tr>
                    <tr>
                      <th scope="row">Manufacturer:</th>
                      <td>{part?.manufacturer}</td>
                    </tr>
                    <tr>
                      <th scope="row">Stock</th>
                      <td>{part?.quantity} pc.</td>
                    </tr>
                    <tr>
                      <th scope="row">Price</th>
                      <td>
                        {request?.price
                          ? request.price.toFixed(5) + " " + request.currency
                          : "-"}{" "}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">Type</th>
                      <td scope="col">{part?.type}</td>
                    </tr>
                    <tr>
                      <th scope="row">Highrunner</th>
                      <td>{request?.isHighrunner == "1" ? "yes" : "no"}</td>
                    </tr>
                    <tr>
                      <th scope="row">Region:</th>
                      <td>{request?.seller?.region}</td>
                    </tr>
                    <tr>
                      <th scope="col">IPN</th>
                      <td scope="col">{part?.internalPart}</td>
                    </tr>
                    <tr>
                      <th scope="col">Location</th>
                      <td scope="col">
                        {request?.seller?.postalCode +
                          " " +
                          request?.seller?.city}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Notes:</th>
                      <td>{request?.notes ? request.partNotes : "-"}</td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <div id="status">
                  <div className="spinner-chase">
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                  </div>
                </div>
              )}
            </div>
          </CardBody>
        </Card>
      </div>
    </Modal>
  )
}

export default PartInfoModal
