import React from "react"
import { Col, Label, Row } from "reactstrap"
import * as Yup from "yup"
import { Field, Form, Formik } from "formik"
import FormikInputField from "helpers/FormikInputField"
import { displayMessage, getCountryList } from "helpers/misc_helpers"
import { UpdateCompany } from "helpers/backend_helper"

const CompanySettingsTab = ({ user, setLoading, setError, setSuccess }) => {
  const countryList = getCountryList()

  const initialValues = {
    id: user?.company?.id,
    name: user?.company?.name,
    vatNumber: user?.company?.vatNumber,
    street: user?.company?.street,
    postalCode: user?.company?.postalCode,
    city: user?.company?.city,
    phoneNumber: user?.company?.phoneNumber,
    country: user?.company?.country,
    region: user?.company?.region,
    logoURL: user?.company?.logoURL,
    serviceActivity: user?.company?.serviceActivity,
  }

  const validationSchema = Yup.object({
    name: Yup.string().required("Please enter your Company Name"),
    vatNumber: Yup.string().required("Please enter your VAT Number"),
    street: Yup.string().required("Please enter your Street"),
    postalCode: Yup.string().required("Please enter your Postal Code"),
    city: Yup.string().required("Please enter your City"),
    phoneNumber: Yup.string().required("Please enter your Phone Number"),
    country: Yup.string().required("Please select your Country"),
    region: Yup.string().required("Please select your Region"),
  })

  var fields = [
    {
      fieldName: "name",
      label: "Company Name",
    },
    {
      fieldName: "vatNumber",
      label: "VAT Number",
    },
    {
      fieldName: "phoneNumber",
      label: "Phone Number",
    },
    {
      fieldName: "street",
      label: "Street",
    },
    {
      fieldName: "postalCode",
      label: "Postal Code",
    },
    {
      fieldName: "city",
      label: "City",
    },
  ]

  const handleSubmit = async values => {
    try {
      setLoading(true)
      await UpdateCompany(values)
      displayMessage("Success", setSuccess)
    } catch (error) {
      if (error?.response?.status === 400)
        displayMessage(error.response.data, setError)
      else displayMessage("An unexpected error happened!", setError)
    }
    setLoading(false)
  }

  return (
    <div>
      <h5 className="mb-4">Company Details</h5>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <Row>
              {fields.map((field, key) => (
                <Col lg="6" key={key}>
                  <FormikInputField
                    fieldName={field.fieldName}
                    label={field.label}
                    errors={errors}
                    touched={touched}
                  />
                </Col>
              ))}
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-cstno-input7">Country:</Label>
                  <Field as="select" className="form-select" name="country">
                    <option disabled value="">
                      -
                    </option>

                    {countryList.map((value, key) => (
                      <option key={key} value={value.code}>
                        {value.name}
                      </option>
                    ))}
                  </Field>
                  {errors.country && touched.country && (
                    <div className="text-danger">{errors.country}</div>
                  )}
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-cstno-input7">Region:</Label>
                  <Field as="select" className="form-select" name="region">
                    <option disabled value="">
                      -
                    </option>
                    <option value="Africa">Africa</option>
                    <option value="Asia">Asia</option>
                    <option value="Australia">Australia</option>
                    <option value="Europe">Europe</option>
                    <option value="North America">North America</option>
                    <option value="South America">South America</option>
                  </Field>
                  {errors.region && touched.region && (
                    <div className="text-danger">{errors.region}</div>
                  )}
                </div>
              </Col>
            </Row>
            <div className="d-flex justify-content-end">
              <button type="submit" className="btn btn-primary">
                Save
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default CompanySettingsTab
