import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import { useLoading } from "helpers/custom_hooks"
import {
  GetMyParts,
  GetMyUser,
  GetRatingFromComapny,
} from "helpers/backend_helper"
import PartList from "./PartList"
import CardUser from "./card-user"

const Dashboard = props => {
  const setLoading = useLoading()
  const [user, setUser] = useState()
  const [userLoading, setUserLoading] = useState(false)
  const [rating, setRating] = useState()
  const [ratingsLoading, setRatingsLoading] = useState(false)
  const [partsLoading, setPartsLoading] = useState(false)

  const [parts, setParts] = useState([])

  useEffect(() => {
    loadParts()
    loadUser()
  }, [])

  useEffect(() => {
    if (user) loadRatings()
  }, [user])

  useEffect(() => {
    if (userLoading || partsLoading) setLoading(true)
    else setLoading(false)
  }, [userLoading, partsLoading])

  const loadParts = async () => {
    setPartsLoading(true)
    const parts = await GetMyParts()
    setParts(parts)
    setPartsLoading(false)
  }

  const loadUser = async () => {
    try {
      setUserLoading(true)
      const result = await GetMyUser()
      setUser(result)
      setUserLoading(false)
    } catch (error) {
      alert("Unexpected error happened!")
    }
  }

  const loadRatings = async () => {
    setRatingsLoading(true)
    const result = await GetRatingFromComapny(user.company.id)
    setRating(result)
    setRatingsLoading(false)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />

          <Row>
            <CardUser user={user} parts={parts} rating={rating} />
            <PartList parts={parts} loadParts={loadParts} />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
