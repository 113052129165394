import PropTypes from "prop-types"
import React from "react"
import { Modal, ModalBody } from "reactstrap"

const MessageModal = ({ show, onCloseClick, displayMessage, color, icon }) => {
  return (
    <Modal size="sm" isOpen={show} toggle={onCloseClick} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 pt-5 text-center">
          <div className="avatar-md mb-4 mx-auto">
            <div
              className={`avatar-title bg-${color} text-${color} bg-opacity-10 font-size-24 rounded-4 p-2`}
            >
              <i className={icon + " font-size-28"}></i>
            </div>
          </div>
          <p className="text-muted font-size-16 mb-4">{displayMessage}</p>

          <div className="hstack gap-2 justify-content-center mb-0">
            <button
              type="button"
              className="btn btn-primary w-50"
              onClick={onCloseClick}
            >
              Close
            </button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

MessageModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
}

export default MessageModal
