import React, { useEffect, useState } from "react"
import { Container } from "reactstrap"
import "flatpickr/dist/themes/material_blue.css"
import { useLoading } from "helpers/custom_hooks"
import { GetWatchlist } from "helpers/backend_helper"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import QCOrders from "./QCOrders"

const QCBuyer = props => {
  const setLoading = useLoading()
  const [parts, setParts] = useState([])

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async _ => {
    setLoading(true)
    const result = await GetWatchlist()
    setParts(result)
    setLoading(false)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Orders" breadcrumbItem="QC Orders" />

          <QCOrders parts={parts} loadParts={loadData} />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default QCBuyer
