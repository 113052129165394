import React, { useState } from "react"
import { Card, CardBody } from "reactstrap"
import { useNavigate } from "react-router-dom"

const Overview = ({ part }) => {
  const [companyInfoModal, setCompanyInfoModal] = useState(false)
  let navigate = useNavigate()

  return (
    <Card className="">
      <CardBody className="pb-0">
        <div className="table-responsive mt-4">
          <table className="table">
            <tbody>
              <tr>
                <th scope="col">Part Number</th>
                <td scope="col">{part?.partNumber}</td>
              </tr>
              <tr>
                <th scope="row">Manufacturer:</th>
                <td>{part?.manufacturer}</td>
              </tr>
              <tr>
                <th scope="row">Stock</th>
                <td>{part?.quantity} pc.</td>
              </tr>
              <tr>
                <th scope="row">Price</th>
                <td>
                  {part?.price
                    ? part.price.toFixed(5) + " " + part.currency
                    : "-"}{" "}
                </td>
              </tr>
              <tr>
                <th scope="row">Region:</th>
                <td>{part?.company?.region}</td>
              </tr>
              <tr>
                <th scope="col">Company</th>
                <td scope="col">
                  {part?.company?.name}{" "}
                  <i
                    className="bx bx-info-circle ms-2 font-size-18"
                    style={{ cursor: "pointer" }}
                    onClick={_ => setCompanyInfoModal(true)}
                  />
                </td>
              </tr>
              <tr>
                <th scope="col">IPN</th>
                <td scope="col">{part?.internalPart}</td>
              </tr>
              <tr>
                <th scope="col">Location</th>
                <td scope="col">
                  {part?.company?.postalCode + " " + part?.company?.city}
                </td>
              </tr>

              <tr>
                <th scope="row">Notes:</th>
                <td>{part?.notes ? part.notes : "-"}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  )
}

export default Overview
