import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Modal, Row, Spinner } from "reactstrap"
import defaultAvatar from "../../assets/images/default-avatar.jpeg"
import moment from "moment"
import { GetLocationByID, GetRatingFromComapny } from "helpers/backend_helper"
import { Rating } from "react-simple-star-rating"
import PremiumBadge from "./PremiumBadge"

const CompanyInfoModal = ({ modal, close, part, locationID }) => {
  const [loading, setLoading] = useState(false)
  const [rating, setRating] = useState(true)
  const [companyObj, setCompanyObj] = useState(part?.company)

  useEffect(() => {
    setCompanyObj(part?.company)
    if (part?.company && modal) loadData()
  }, [locationID, part?.company, modal])

  const loadData = async () => {
    setLoading(true)
    const result = await GetRatingFromComapny(part?.company.id)
    setRating(result)

    if (locationID) loadLocations()
    else setLoading(false)
  }

  const loadLocations = async () => {
    try {
      const location = await GetLocationByID(locationID)

      setCompanyObj({
        ...companyObj,
        street: location.street,
        postalCode: location.postalCode,
        city: location.city,
        country: location.country,
        region: location.region,
      })
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  return (
    <Modal isOpen={modal} toggle={close}>
      <>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Company Info
          </h5>
          <button
            type="button"
            onClick={close}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Card>
            <CardBody>
              {loading ? (
                <div className="w-100 text-center">
                  <Spinner color="dark" />
                </div>
              ) : (
                <Row>
                  <Col className="d-flex align-items-center">
                    <img
                      src={companyObj?.logoURL ?? defaultAvatar}
                      alt=""
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100px",
                        objectFit: "cover",
                      }}
                    />
                  </Col>
                  <Col lg="8">
                    <h5 className="mt-2 mb-1 d-flex">
                      {companyObj?.name}{" "}
                      <PremiumBadge accountType={part?.user?.accountType} />
                    </h5>
                    <p className="text-muted mb-1">
                      {companyObj?.street}
                      <br />
                      {companyObj?.postalCode} {companyObj?.city}
                      <br />
                      {companyObj?.country} - {companyObj?.region}
                      <br />
                      {companyObj?.vatNumber}
                    </p>
                    <p className="text-muted mb-1">
                      Member since:{" "}
                      {moment(companyObj?.createdAt).format("MMMM YYYY")}
                    </p>

                    {rating && (
                      <div style={{ width: "fit-content" }}>
                        <Rating
                          readonly
                          allowFraction
                          initialValue={rating.avgRating}
                          fillClassName="text-primary"
                          size="20"
                        />
                        <small className="text-muted text-center">
                          {" "}
                          {rating.numberOfRatings} rating(s)
                        </small>
                      </div>
                    )}
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        </div>
      </>
    </Modal>
  )
}

export default CompanyInfoModal
