import { Field, Form, Formik } from "formik"
import FormikInputField from "helpers/FormikInputField"
import { UpdatePartList } from "helpers/backend_helper"
import { useLoading } from "helpers/custom_hooks"
import React, { useState } from "react"
import { Col, Label, Row, TabPane } from "reactstrap"
import { displayMessage } from "helpers/misc_helpers"
import FormikPriceInputField from "helpers/FormikPriceInputField"

const QCSummaryTab = ({ nextTab, part }) => {
  const fields = [
    {
      fieldName: "internalPart",
      label: "IPN",
      component: FormikInputField,
    },
    {
      fieldName: "partNumber",
      label: "Manufacturer Part Number",
      component: FormikInputField,
    },
    {
      fieldName: "manufacturer",
      label: "Manufacturer",
      component: FormikInputField,
    },
    {
      fieldName: "auftragInputReference",
      label: "internal Order Number",
      component: FormikInputField,
    },
    {
      fieldName: "quantity",
      label: "Quantity",
      component: FormikInputField,
    },
    {
      fieldName: "description",
      label: "Description",
      component: FormikInputField,
    },
    {
      fieldName: "notes",
      label: "Notes",
      component: FormikInputField,
    },
  ]

  const setLoading = useLoading()
  const [error, setError] = useState()
  const initialValues = {
    id: part?.id,
    internalPart: part?.internalPart ?? "Test",
    manufacturer: part?.manufacturer ?? "Infineon",
    notes: part?.notes ?? "",
    partNumber: part?.partNumber ?? "part1",
    quantity: part?.quantity ?? "100",
    description: part?.description ?? "Part 12345",
  }

  const handleSubmit = async values => {
    try {
      const price = parseFloat(("" + values.price)?.replace(",", "."))
      const qty = parseInt(values.quantity)

      if ((isNaN(price) && values.price) || isNaN(qty))
        return displayMessage("Invalid Number Format!", setError)

      setLoading(true)

      await UpdatePartList({
        ...values,
        price: isNaN(price) ? undefined : price,
        quantity: qty,
      })
      onSuccess()
    } catch (error) {
      error?.response?.status == 400
        ? displayMessage(error.response.data, setError)
        : displayMessage("An unhandled exception happend!", setError)
      setLoading(false)
    }
  }
  return (
    <TabPane tabId={3}>
      <div className="row justify-content-center">
        <Col lg="6">
          <div className="text-center">
            {/* <div className="mb-4">
              <i className="mdi mdi-check-circle-outline text-primary display-4" />
            </div> */}
            <div>
              <h5 className="text-start">Summary:</h5>

              <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                {({ errors, touched }) => (
                  <Form>
                    <Row>
                      {fields.map((field, key) => (
                        <Col lg="6" key={key}>
                          <field.component
                            fieldName={field.fieldName}
                            label={field.label}
                            errors={errors}
                            touched={touched}
                          />
                        </Col>
                      ))}
                    </Row>
                  </Form>
                )}
              </Formik>

              <button
                type="submit"
                onClick={nextTab}
                className="btn btn-primary"
              >
                Confirm
              </button>
            </div>
          </div>
        </Col>
      </div>
    </TabPane>
  )
}

export default QCSummaryTab
