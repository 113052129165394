import NumberInput from "components/Common/NumberInput"
import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Collapse, Row } from "reactstrap"
import { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
const { SearchBar } = Search

const PartFilter = ({
  isOpen,
  parts,
  setParts,
  isWatchlist = false,
  searchProps,
}) => {
  const [manufacturer, setManufacturer] = useState("")
  const [mpn, setMPN] = useState("")
  const [priceFrom, setPriceFrom] = useState(0)
  const [priceTo, setPriceTo] = useState(0)
  const [quantityFrom, setQuantityFrom] = useState(0)
  const [quantityTo, setQuantityTo] = useState(0)
  const [isHighrunner, setIsHighrunner] = useState("")
  const [currency, setCurrency] = useState("")
  const [type, setType] = useState("")

  useEffect(() => {
    filterParts()
  }, [
    manufacturer,
    priceFrom,
    priceTo,
    quantityFrom,
    quantityTo,
    isHighrunner,
    type,
    currency,
    mpn,
  ])

  const filterParts = () => {
    let result = filterPrices(parts)
    result = filterQuantitys(result)
    result = filterHighrunner(result)
    result = filterTypes(result)
    result = filterCurrency(result)
    result = filterManufacturer(result)
    result = filterMPN(result)
    setParts(result)
  }

  useEffect(() => {
    filterParts()
  }, [parts])

  const filterMPN = list => {
    if (mpn)
      return list.filter(t =>
        t.partNumber?.toLowerCase()?.startsWith(mpn?.toLowerCase())
      )

    return list
  }

  const filterManufacturer = list => {
    if (manufacturer)
      return list.filter(t =>
        t.manufacturer?.toLowerCase()?.startsWith(manufacturer?.toLowerCase())
      )

    return list
  }

  const filterTypes = list => {
    if (type) return list.filter(t => t.type == type)

    return list
  }

  const filterHighrunner = list => {
    if (isHighrunner) return list.filter(t => t.isHighrunner == isHighrunner)

    return list
  }

  const filterQuantitys = list => {
    if (quantityFrom) list = list.filter(t => t.quantity >= quantityFrom)
    if (quantityTo) list = list.filter(t => t.quantity <= quantityTo)

    return list
  }

  const filterPrices = list => {
    if (priceFrom) list = list.filter(t => t.price >= priceFrom)
    if (priceTo) list = list.filter(t => t.price <= priceTo)

    return list
  }
  const filterCurrency = list => {
    if (currency) return list.filter(t => t.currency == currency)
    return list
  }

  const clearAll = _ => {
    setPriceFrom(0)
    setPriceTo(0)
    setQuantityFrom(0)
    setQuantityTo(0)
    setIsHighrunner("")
    setType("")
    setCurrency("")
    setManufacturer("")
    setMPN("")
    searchProps.onClear()
  }

  return (
    <Collapse isOpen={isOpen}>
      <Card>
        <CardBody className="border shadow-none text-muted mb-0">
          <Row>
            <Col
              className="d-flex flex-column"
              style={{ maxWidth: "200px", minWidth: "150px" }}
            >
              <label>Search:</label>
              <SearchBar {...searchProps} placeholder=" " />
            </Col>
            <Col style={{ maxWidth: "200px", minWidth: "150px" }}>
              <label>MPN:</label>
              <input
                className="form-control"
                value={mpn}
                onChange={e => setMPN(e.target.value)}
              />
            </Col>
            <Col style={{ maxWidth: "200px", minWidth: "150px" }}>
              <label>Manufacturer:</label>
              <input
                className="form-control"
                value={manufacturer}
                onChange={e => setManufacturer(e.target.value)}
              />
            </Col>
            <Col style={{ maxWidth: "200px", minWidth: "150px" }}>
              <label>Quantity from:</label>
              <NumberInput
                initial={quantityFrom}
                onChange={e => setQuantityFrom(e)}
              />
            </Col>
            <Col style={{ maxWidth: "200px", minWidth: "150px" }}>
              <label>Quantity to:</label>
              <NumberInput
                initial={quantityTo}
                onChange={e => setQuantityTo(e)}
              />
            </Col>
            <Col style={{ maxWidth: "200px", minWidth: "150px" }}>
              <label>Price from:</label>
              <NumberInput
                initial={priceFrom}
                onChange={e => setPriceFrom(e)}
              />
            </Col>
            <Col style={{ maxWidth: "200px", minWidth: "150px" }}>
              <label>Price to:</label>
              <NumberInput initial={priceTo} onChange={e => setPriceTo(e)} />
            </Col>

            {!isWatchlist && (
              <>
                <Col style={{ maxWidth: "200px", minWidth: "150px" }}>
                  <label>Type:</label>
                  <select
                    className="form-select"
                    value={type}
                    onChange={e => setType(e.target.value)}
                  >
                    <option value="">-</option>
                    <option value="APQ">APQ</option>
                    <option value="Excess">Excess</option>
                  </select>
                </Col>
                <Col style={{ maxWidth: "200px", minWidth: "150px" }}>
                  <label>Is Highrunner:</label>
                  <select
                    className="form-select"
                    value={isHighrunner}
                    onChange={e => setIsHighrunner(e.target.value)}
                  >
                    <option value="">-</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                </Col>{" "}
              </>
            )}
            <Col style={{ maxWidth: "200px", minWidth: "150px" }}>
              <label>Currency:</label>
              <select
                className="form-select"
                value={currency}
                onChange={e => setCurrency(e.target.value)}
              >
                <option value="">-</option>
                <option value="EUR">EUR</option>
                <option value="USD">USD</option>
                <option value="GBP">GBP</option>
                <option value="YEN">YEN</option>
              </select>
            </Col>

            <Col className="w-auto">
              <button className="btn btn-danger mt-4" onClick={clearAll}>
                Clear All
              </button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Collapse>
  )
}

export default PartFilter
