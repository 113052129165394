import React, { useState } from "react"
import { Alert, Col, Label, Row, TabPane } from "reactstrap"
import { Field, Formik, Form } from "formik"
import FormikInputField from "../../../helpers/FormikInputField"
import * as Yup from "yup"
import { useLoading } from "helpers/custom_hooks"
import { CreateCompany } from "helpers/backend_helper"
import { displayMessage, getCountryList } from "helpers/misc_helpers"

const CompanyInfoTab = ({ nextTab, setCompany }) => {
  const [error, setError] = useState()
  const setLoading = useLoading()
  const countryList = getCountryList()

  const initialValues = {
    name: "",
    serviceActivity: "",
    street: "",
    postalCode: "",
    city: "",
    phoneNumber: "",
    country: "",
    region: "",
    vatNumber: "",
  }

  const validationSchema = Yup.object({
    name: Yup.string().required("Please enter your Company Name"),
    serviceActivity: Yup.string().required(
      "Please enter your Service Activity"
    ),
    street: Yup.string().required("Please enter your Street"),
    postalCode: Yup.string().required("Please enter your Postal Code"),
    city: Yup.string().required("Please enter your City"),
    phoneNumber: Yup.string().required("Please enter your Phone Number"),
    country: Yup.string().required("Please select your Country"),
    region: Yup.string().required("Please select your Region"),
    vatNumber: Yup.string().required("Please enter your VAT Number"),
  })

  var fields = [
    {
      fieldName: "name",
      label: "Company Name",
    },
    {
      fieldName: "serviceActivity",
      label: "Scope of Business",
    },
    {
      fieldName: "vatNumber",
      label: "VAT Number",
    },
    {
      fieldName: "phoneNumber",
      label: "Phone Number",
    },
    {
      fieldName: "street",
      label: "Street",
    },
    {
      fieldName: "postalCode",
      label: "Postal Code",
    },
    {
      fieldName: "city",
      label: "City",
    },
  ]

  const handleSubmit = async values => {
    try {
      setLoading(true)
      const newCompany = await CreateCompany(values)
      setCompany(newCompany)
      nextTab()
      setLoading(false)
    } catch (error) {
      if (error?.response?.status === 400)
        displayMessage(error.response.data, setError)
      else displayMessage("An unexpected error happened!", setError)
      console.error(error)
    }
    setLoading(false)
  }

  return (
    <TabPane tabId={2}>
      {error && (
        <Alert color="danger" style={{ marginTop: "13px" }}>
          {error}
        </Alert>
      )}
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <Row>
                {fields.map((field, key) => (
                  <Col lg="6" key={key}>
                    <FormikInputField
                      fieldName={field.fieldName}
                      label={field.label}
                      errors={errors}
                      touched={touched}
                    />
                  </Col>
                ))}
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-cstno-input7">Country:</Label>
                    <Field as="select" className="form-select" name="country">
                      <option disabled value="">
                        -
                      </option>

                      {countryList.map((value, key) => (
                        <option key={key} value={value.code}>
                          {value.name}
                        </option>
                      ))}
                    </Field>
                    {errors.country && touched.country && (
                      <div className="text-danger">{errors.country}</div>
                    )}
                  </div>
                </Col>

                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-cstno-input7">Region:</Label>
                    <Field as="select" className="form-select" name="region">
                      <option disabled value="">
                        -
                      </option>
                      <option value="Africa">Africa</option>
                      <option value="Asia">Asia</option>
                      <option value="Australia">Australia</option>
                      <option value="Europe">Europe</option>
                      <option value="North America">North America</option>
                      <option value="South America">South America</option>
                    </Field>
                    {errors.region && touched.region && (
                      <div className="text-danger">{errors.region}</div>
                    )}
                  </div>
                </Col>
              </Row>
              <div className="d-flex justify-content-end">
                <button type="submit" className="btn btn-primary">
                  Next
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </TabPane>
  )
}

export default CompanyInfoTab
