import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Col, Collapse, Form, Row, TabPane, Tooltip } from "reactstrap"
import classnames from "classnames"

const QCTypeTab = ({ nextTab, previousTab }) => {
  const [col9, setcol9] = useState(false)
  const [col10, setcol10] = useState(false)
  const [col11, setcol11] = useState(false)
  const [col12, setcol12] = useState(false)

  const t_col9 = () => {
    setcol9(!col9)
    setcol10(false)
    setcol11(false)
  }

  const t_col10 = () => {
    setcol10(!col10)
    setcol9(false)
    setcol11(false)
  }

  const t_col11 = () => {
    setcol11(!col11)
    setcol10(false)
    setcol9(false)
  }

  const t_col12 = () => {
    setcol12(!col11)
    setcol11(false)
    setcol10(false)
    setcol9(false)
  }

  return (
    <TabPane tabId={1}>
      <Form>
        <Row>
          <Col lg="12">
            <h5 className="font-size-14 mb-4">Request Type</h5>
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFlushOne">
                  <button
                    className={classnames("accordion-button", "fw-medium", {
                      collapsed: !col9,
                    })}
                    type="button"
                    onClick={t_col9}
                    style={{ cursor: "pointer" }}
                  >
                    Commercial
                  </button>
                </h2>

                <Collapse isOpen={col9} className="accordion-collapse">
                  <div className="accordion-body">
                    <div className="form-check mb-3">
                      <input className="form-check-input" type="radio" />
                      <label id="accountTypeInfo" className="form-check-label">
                        Documentation & Packaging Inspection
                      </label>
                    </div>
                    <div className="form-check mb-3">
                      <input className="form-check-input" type="radio" />
                      <label id="accountTypeInfo" className="form-check-label">
                        External Visual Inspection
                      </label>
                    </div>
                    <div className="form-check mb-3">
                      <input className="form-check-input" type="radio" />
                      <label id="accountTypeInfo" className="form-check-label">
                        X-Ray
                      </label>
                    </div>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFlushTwo">
                  <button
                    className={classnames("accordion-button", "fw-medium", {
                      collapsed: !col10,
                    })}
                    type="button"
                    onClick={t_col10}
                    style={{ cursor: "pointer" }}
                  >
                    Industrial & Military
                  </button>
                </h2>

                <Collapse isOpen={col10} className="accordion-collapse">
                  <div className="accordion-body">
                    <div className="form-check mb-3">
                      <input className="form-check-input" type="radio" />
                      <label id="accountTypeInfo" className="form-check-label">
                        EVI-1 - General EVI (Full Lot)
                      </label>
                    </div>
                    <div className="form-check mb-3">
                      <input className="form-check-input" type="radio" />
                      <label id="accountTypeInfo" className="form-check-label">
                        EVI-2 - Detailed EVI (Sample)
                      </label>
                    </div>
                    <div className="form-check mb-3">
                      <input className="form-check-input" type="radio" />
                      <label id="accountTypeInfo" className="form-check-label">
                        EVI-3 - Testing for Remarking (EVI)
                      </label>
                    </div>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFlushThree">
                  <button
                    className={classnames("accordion-button", "fw-medium", {
                      collapsed: !col11,
                    })}
                    type="button"
                    onClick={t_col11}
                    style={{ cursor: "pointer" }}
                  >
                    Automotive & Medical
                  </button>
                </h2>
                <Collapse isOpen={col11} className="accordion-collapse">
                  <div className="accordion-body">
                    <div className="form-check mb-3">
                      <input className="form-check-input" type="radio" />
                      <label id="accountTypeInfo" className="form-check-label">
                        EVI-1 - General EVI (Full Lot)
                      </label>
                    </div>
                    <div className="form-check mb-3">
                      <input className="form-check-input" type="radio" />
                      <label id="accountTypeInfo" className="form-check-label">
                        EVI-2 - Detailed EVI (Sample)
                      </label>
                    </div>
                    <div className="form-check mb-3">
                      <input className="form-check-input" type="radio" />
                      <label id="accountTypeInfo" className="form-check-label">
                        EVI-3 - Testing for Remarking (EVI)
                      </label>
                    </div>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFlushThree">
                  <button
                    className={classnames("accordion-button", "fw-medium", {
                      collapsed: !col12,
                    })}
                    type="button"
                    onClick={t_col12}
                    style={{ cursor: "pointer" }}
                  >
                    Aerospace
                  </button>
                </h2>
                <Collapse isOpen={col12} className="accordion-collapse">
                  <div className="accordion-body">
                    <div className="form-check mb-3">
                      <input className="form-check-input" type="radio" />
                      <label id="accountTypeInfo" className="form-check-label">
                        EVI-1 - General EVI (Full Lot)
                      </label>
                    </div>
                    <div className="form-check mb-3">
                      <input className="form-check-input" type="radio" />
                      <label id="accountTypeInfo" className="form-check-label">
                        EVI-2 - Detailed EVI (Sample)
                      </label>
                    </div>
                    <div className="form-check mb-3">
                      <input className="form-check-input" type="radio" />
                      <label id="accountTypeInfo" className="form-check-label">
                        EVI-3 - Testing for Remarking (EVI)
                      </label>
                    </div>
                  </div>
                </Collapse>
              </div>
            </div>
          </Col>
        </Row>
        <div className="d-flex justify-content-end">
          <Link
            to="#"
            className="btn btn-primary disabled me-3"
            onClick={previousTab}
          >
            Previous
          </Link>
          <Link to="#" className="btn btn-primary" onClick={nextTab}>
            Next
          </Link>
        </div>
      </Form>
    </TabPane>
  )
}

export default QCTypeTab
