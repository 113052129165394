import React, { useState } from "react"

import { Alert, Card, CardSubtitle, Col, Row, TabPane } from "reactstrap"
import { useLoading } from "helpers/custom_hooks"
import { UpdateCompany, UpdateLogo, UploadLogo } from "helpers/backend_helper"
import Dropzone from "react-dropzone"
import { displayMessage } from "helpers/misc_helpers"

const LogoUploadTab = ({ nextTab, company }) => {
  const [error, setError] = useState()
  const setLoading = useLoading()
  const [selectedFiles, setselectedFiles] = useState([])

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const handleUpload = async () => {
    if (selectedFiles.length > 0) {
      if (!checkFile(selectedFiles[0])) return

      setLoading(true)
      const formData = new FormData()
      formData.append("file", selectedFiles[0])

      try {
        const result = await UploadLogo(formData)
        await UpdateLogo({ logoURL: result.url })
      } catch (error) {
        console.error("Error uploading image: ", error)
        setError("An unexpected error happened!")
      }
      setLoading(false)
    }
    nextTab()
  }

  const checkFile = file => {
    if (file.size > 2 * 1024 * 1024) {
      displayMessage("File size exceeds maximum size of 2MB!", setError)
      return false
    }

    if (!file.type.startsWith("image/")) {
      displayMessage("Invalid file format!", setError)
      return false
    }
    return true
  }

  return (
    <TabPane tabId={3}>
      {error && (
        <Alert color="danger" style={{ marginTop: "13px" }}>
          {error}
        </Alert>
      )}

      <h6 className="card-title">Logo Upload (optional)</h6>
      <CardSubtitle className="mb-3">
        You can drop your logo here or click in the dropzone to select your
        file.
      </CardSubtitle>
      <Dropzone
        onDrop={acceptedFiles => {
          handleAcceptedFiles(acceptedFiles)
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div className="dropzone">
            <div className="dz-message needsclick mt-2" {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="mb-3">
                <i className="display-4 text-muted bx bxs-cloud-upload" />
              </div>
              <h4>Drop files here or click to upload.</h4>
            </div>
          </div>
        )}
      </Dropzone>
      <div className="dropzone-previews mt-3" id="file-previews">
        {selectedFiles.map((f, i) => {
          return (
            <Card
              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
              key={i + "-file"}
            >
              <div className="p-2">
                <Row className="align-items-center">
                  <Col className="col-auto">
                    <img
                      data-dz-thumbnail=""
                      height="80"
                      className="avatar-sm rounded bg-light"
                      alt={f.name}
                      src={f.preview}
                    />
                  </Col>
                  <Col>
                    <a className="text-muted font-weight-bold">{f.name}</a>
                    <p className="mb-0">
                      <strong>{f.formattedSize}</strong>
                    </p>
                  </Col>
                </Row>
              </div>
            </Card>
          )
        })}
      </div>
      <div className="d-flex justify-content-end mt-2">
        <button onClick={handleUpload} className="btn btn-primary">
          Next
        </button>
      </div>
    </TabPane>
  )
}

export default LogoUploadTab
